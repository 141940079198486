import { useContext, createContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import ContentContext from './ContentContext';

const CourseContext = createContext();

export const CourseContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [token, setToken] = useState(null);
  const [courses, setCourse] = useState([]);
  const [topCourses, setTopCourses] = useState([]);
  const [courseId, setCourseId] = useState(() =>
    localStorage.getItem('courseId')
  );
  const [courseData, setCourseData] = useState({});

  useEffect(() => {
    if (courseId) {
      // Ensure courseId is not null or undefined
      localStorage.setItem('courseId', courseId);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseData) {
      console.log('courseData', courseData);
    }
  }, [courseData]);

  useEffect(() => {
    if (courses) console.log('courses', courses);
  }, [courses]);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course/all`, { headers: headers })
        .then(res => {
          console.log('AllCourseres', res.data.courses);
          setCourse(res.data.courses);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {}
  };
  const handleCourseDelete = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Deleting the course ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .delete(`${backendUrl}/course?courseId=${id}`, {
          headers: headers,
        })
        .then(res => {
          console.log(res);
          fetchData();
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getCourse = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course/specific?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('Course Data', res.data);
          setCourseData(res.data.course);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {}
  };
  const getNoAuthCourse = async () => {
    setCourseData({});
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('Course Data', res.data);
          setCourseData(res.data.course);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {}
  };

  const getComplitionCertificate = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    console.log(
      'getComplitionCertificateuserid:',
      userId,
      'getComplitionCertificatecourseId:',
      courseId
    );
    try {
      loadingToast = toast.loading('Generating your certificate ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .get(
          `${backendUrl}/user/rs/generate_completion_certificate?userId=${userId}&courseId=${courseId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('getcompletioncertificateres', res.data);
          const pdfUrl = `${backendUrl}${res.data.data.certificateLink}`;
          // const pdfUrl = URL.createObjectURL(pdfBlob);

          window.open(pdfUrl, '_blank');
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getExcellenceCertificate = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    console.log(
      'getComplitionCertificateuserid:',
      userId,
      'getComplitionCertificatecourseId:',
      courseId
    );
    try {
      loadingToast = toast.loading('Generating your certificate ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(
          `${backendUrl}/user/rs/generate_excellence_certificate?userId=${userId}&courseId=${courseId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('getcompletioncertificateres', res.data);

          // setScoreCard(res.data.data)
          // const pdfBlob = new Blob([res.data.data.certificateLink], { type: 'application/pdf' });
          const pdfUrl = `${backendUrl}${res.data.data.certificateLink}`;
          // const pdfUrl = URL.createObjectURL(pdfBlob);
          console.log('pdfUrl', pdfUrl);
          window.open(pdfUrl, '_blank');
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <CourseContext.Provider
      value={{
        courseData,
        courses,
        getCourse,
        handleCourseDelete,
        fetchData,
        setToken,
        setCourseId,
        setCourseData,
        courseId,
        getComplitionCertificate,
        getExcellenceCertificate,
        getNoAuthCourse,
        setTopCourses,
        topCourses,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseContext;

export const useCourse = () => useContext(CourseContext);
