import React, { createContext, useContext, useEffect, useState } from 'react';
import CourseContext from './CourseListContext';
import EnvContext from './EnvContext';
import toast from 'react-hot-toast';
import axios from 'axios';
import ContentContext from './ContentContext';
import { useNavigate } from 'react-router-dom';

const ProjectContext = createContext();
export const ProjectContextProvider = ({ children }) => {
  const { fetchUserScoreboard } = useContext(ContentContext);
  const [token, setToken] = useState(null);
  const { backendUrl } = useContext(EnvContext);
  const { scorecard } = useContext(ContentContext);
  const [projectId, setProjectId] = useState(null);
  const [ProjectData, setProjectData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [latestSubProjectId, setLatestSubProjectId] = useState(null);
  const [submittedProjects, setSubmittedProject] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const userId = localStorage.getItem('userId');
  const courseId = localStorage.getItem('courseId');
  const naviagte = useNavigate();

  const [projects, setProjects] = useState([]);

  const fetchAllProjects = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Project List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/course/project/all?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data);
          setProjects(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleProjectDelete = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Deleting the project ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .delete(`${backendUrl}/course/project?projectId=${projectId}`, {
          headers: headers,
        })
        .then(res => {
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          fetchAllProjects();
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getProject = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Project List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/course/project?projectId=${id}`, {
          headers: headers,
        })

        .then(res => {
          console.log('getprojectres.data.data', res.data.data);
          setProjectData(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const startProject = async (courseId, projectId, index) => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log('rescourseId', courseId, 'resprojectId', projectId);

    try {
      await axios
        .post(
          `${backendUrl}/user/rs/project_submission/start?courseId=${courseId}&projectId=${projectId}`,
          null,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log(res);
          setCurrentIndex(currentIndex === index ? null : index);
          fetchUserScoreboard(userId, courseId);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectSubmission = async (projectId, courseId, userId) => {
    localStorage.setItem('CorId', courseId);
    localStorage.setItem('ProId', projectId);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Project Submission List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      let apiUrl = `${backendUrl}/user/rs/project_submission/specific?`;
      if (courseId) {
        apiUrl += `courseId=${courseId}&`;
      }
      if (projectId) {
        apiUrl += `projectId=${projectId}&`;
      }
      if (userId) {
        apiUrl += `userId=${userId}&`;
      }
      // Remove the trailing '&' if any
      apiUrl = apiUrl.replace(/&$/, '');

      await axios
        .get(apiUrl, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data);
          setSubmittedProject(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          naviagte('/admin/submited_projects');
        });
    } catch (err) {
      console.log(err);
      toast.error(`${err.response.data.message}`, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const giveProjectScores = async (id, postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Project List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(
          `${backendUrl}/user/rs/project_submission/review?submissionId=${id}`,
          postData,
          {
            headers: headers,
          }
        )

        .then(res => {
          console.log('giveProjectScores', res.data.data);
          // setProjectData(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  useEffect(() => {
    if (
      scorecard &&
      Array.isArray(scorecard.project) &&
      scorecard.project.length > 0
    ) {
      const filteredProjects = scorecard.project.filter(
        project =>
          project && project.project && project.project._id === projectId
      );

      filteredProjects.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      if (filteredProjects.length > 0) {
        const latestProject = filteredProjects[0];
        console.log(
          'filteredProjects',
          filteredProjects,
          'latestProject',
          latestProject ? latestProject._id : 'No latest project found'
        );
        setFilteredProjects(filteredProjects);
        setLatestSubProjectId(latestProject ? latestProject._id : null);
      } else {
        console.log('No projects found for the given projectId');
        setFilteredProjects([]);
        setLatestSubProjectId(null);
      }
    }
  }, [projectId, scorecard]);

  const submitProject = async (
    submittedProjectId,
    courseId,
    projectId,
    submissionLinks
  ) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    const postData = {
      submissionLinks: submissionLinks,
    };
    console.log(
      'submittedProjectId',
      submittedProjectId,
      'courseId',
      courseId,
      'projectId',
      projectId,
      'submissionLinks',
      submissionLinks
    );
    try {
      loadingToast = toast.loading('Submitting Project', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(
          `${backendUrl}/user/rs/project_submission?submittedProjectId=${submittedProjectId}&courseId=${courseId}&projectId=${projectId}`,
          postData,
          {
            headers: headers,
          }
        )
        .then(res => {
          fetchUserScoreboard(userId, courseId);
          console.log('getprojectres', res.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        projectId,
        fetchAllProjects,
        setToken,
        setProjectId,
        handleProjectDelete,
        getProject,
        ProjectData,
        setCurrentIndex,
        currentIndex,
        startProject,
        getProjectSubmission,
        submitProject,
        latestSubProjectId,
        submittedProjects,
        giveProjectScores,
        filteredProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
