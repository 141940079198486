import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import CartContext from './CartContext';

const AuthContext = createContext({
  userId: null,
  role: null,
  userData: null,
  setUser: () => {},
  handelSignOut: () => {},
  errorHandleLogout: () => {},
  handleUserData: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [role, setRole] = useState('');
  // const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({});
  const [blockedUsers, setBlockedUsers] = useState([]);
  // const [success, setSucess] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const savedLocation = localStorage.getItem('savedLocation');
  const [products, setProducts] = useState([]);

  const setUser = async value => {
    localStorage.removeItem('unAuthCartItems');
    let loadingToast;

    console.log(value);
    try {
      loadingToast = toast.loading('SignIn in progress ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .post(`${BACKEND_URL}/user/signin`, value)
        .then(res => {
          console.log('res', res);
          localStorage.setItem('token', res.data.data.token);
          localStorage.setItem('userId', res.data.data.userId);
          localStorage.setItem('role', res.data.data.role);
          if (isChecked) {
            localStorage.setItem('rememberedEmail', value.email);
            localStorage.setItem('rememberedPassword', value.password);
          }
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          if (res.data.data.role === 'user') {
            if (savedLocation) navigate(savedLocation);
            else navigate('/dashboard');
          } else navigate('/admin/site_manager');
        })
        .catch(err => {
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      console.log('err', err);
      toast.error(`${err.response.data.message}`, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
        handleUserData();
      }
    }
  };

  const handleUserData = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let apiUrl = `${BACKEND_URL}/user?`;
    if (userId) apiUrl += `userId=${userId}`;
    if (token) {
      try {
        await axios
          .get(apiUrl, {
            headers: headers,
          })
          .then(res => {
            console.log(res);
            setUserData(res.data.user);
            localStorage.setItem('dp', res.data.user.dp);
            localStorage.setItem('name', res.data.user.name);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  //   useEffect(()=>{
  // if(userData) console.log('userData',userData)
  //   },[userData])
  const handelSignOut = () => {
    const confirmLogOut = window.confirm('Are you sure you want to logout?');
    if (confirmLogOut) {
      toast.success('LogOut Successfully');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('role');
      localStorage.removeItem('dp');
      localStorage.removeItem('name');
      localStorage.removeItem('lastRoute');
      localStorage.removeItem('firstFive');
      localStorage.removeItem('tabIndex');
      navigate('/');
    }
  };

  const errorHandleLogout = () => {
    toast.success('LogOut Successfully');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('dp');
    localStorage.removeItem('name');
    localStorage.removeItem('lastRoute');
    localStorage.removeItem('firstFive');
    localStorage.removeItem('tabIndex');
    navigate('/');
  };

  const getSpecificUsers = async courseId => {
    const role = localStorage.getItem('role');
    if (role !== 'admin') return;
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;

    let apiUrl = `${BACKEND_URL}/user/course/specific_type?`;
    if (courseId) {
      apiUrl += `courseId=${courseId}`;
    }
    try {
      axios.get(apiUrl, { headers: headers }).then(res => {
        console.log('res', res);
        setUsers(res.data.users);

        setLoading(true);
      });
    } catch (err) {
      console.log(err);
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  useEffect(() => {
    const storedValues = JSON.parse(sessionStorage.getItem('userData'));
    if (storedValues) {
      setUserData(storedValues);
    }
  }, []);

  // Update sessionStorage when values change
  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  const blockUser = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    console.log('userId', userId);
    try {
      loadingToast = toast.loading(' blocking ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .delete(`${BACKEND_URL}/user/block?userId=${userId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('Blockuserres', res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getSpecificUsers();
          getBlockUser();
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  const getBlockUser = async () => {
    const role = localStorage.getItem('role');
    if (role !== 'admin') return;
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios
        .get(`${BACKEND_URL}/user/blocked_list`, { headers: headers })
        .then(res => {
          console.log('blockuserres', res.data.users);
          setBlockedUsers(res.data.users);
        });
    } catch (err) {
      console.log('err', err);
    }
  };

  const unBlock = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      await axios
        .put(`${BACKEND_URL}/user/retrive?userId=${userId}`, null, {
          headers: headers,
        })
        .then(res => {
          console.log('unBlockres', res);

          getBlockUser();
          getSpecificUsers();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('userId', userId);
    let loadingToast;
    try {
      loadingToast = toast.loading(' user deleting  ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .delete(`${BACKEND_URL}/user/temp?userId=${userId}`, {
          headers: headers,
        })
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getSpecificUsers();
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const userClean = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('userId', userId);
    let loadingToast;
    try {
      loadingToast = toast.loading('Cleaning up', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .delete(`${BACKEND_URL}/user/clean_account?userId=${userId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('userCleanres', res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getAllProducts = async () => {
    let loadingToast;
    try {
      loadingToast = toast.loading('Products are loading ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .get(`${BACKEND_URL}/course/all`)
        .then(res => {
          setProducts(res.data.courses);
        })
        .catch(err => {
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch {
      alert('Something went wrong, Try logging in again');

      // errorHandleLogout();
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  return (
    <AuthContext.Provider
      value={{
        setUser,
        handelSignOut,
        errorHandleLogout,
        userData,
        handleUserData,
        blockUser,
        getBlockUser,
        blockedUsers,
        unBlock,
        handleDelete,
        userClean,
        getSpecificUsers,
        loading,
        users,
        isChecked,
        setIsChecked,
        products,
        setProducts,
        getAllProducts,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
