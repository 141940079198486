import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import '../../../../allcss/reviewPage.css';
import { Rating } from './Rating';
import toast from 'react-hot-toast';
import EnvContext from '../../../../context/EnvContext';
import axios from 'axios';
import { BiSort } from 'react-icons/bi';
import { DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons';

const Review = ({ courseData, isAdminViewing = false }) => {
  const [selectedStars, setSelectedStars] = useState(1);
  const [selectedStarRating, setSelectedStarRating] = useState(1);
  const [myComment, setMyComment] = useState('');
  const [comment, setComment] = useState('');
  const [myReview, setMyReview] = useState({});
  const [isAddedReview, setIsAddedReview] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [overallRating, setOverallRating] = useState([]);
  const [starCounts, setStarCounts] = useState({});
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');
  const [sortBy, setSortBy] = useState('dateAsc');
  const role = localStorage.getItem('role');
  const [admin, setAdmin] = useState(false);
  const [reviewToBeUpdated, setReviewToBeUpdated] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (role === 'admin') {
      setAdmin(true);
    }
  }, [role]); // Only re-run the effect if role changes
  useEffect(() => {
    if (role === 'admin' && myReview) {
      setIsAddedReview(true);
    }
  }, [role, myReview]); // Re-run the effect if role or myReview changes

  const getTimeAgoString = time => {
    const currentTime = new Date();
    const messageTime = new Date(time);
    const timeDiffInSeconds = Math.floor((currentTime - messageTime) / 1000);

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds} secs ago`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes} mins ago`;
    } else if (timeDiffInSeconds < 86400) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours} hrs ago`;
    } else {
      const days = Math.floor(timeDiffInSeconds / 86400);
      return `${days} days ago`;
    }
  };

  const filterAndSortReviews = (reviews, sortBy) => {
    let sortedReviews = [...reviews];

    // Sorting based on the selected sortBy value
    if (sortBy === 'nameAsc') {
      sortedReviews.sort((a, b) =>
        a.reviewingUser.name.localeCompare(b.reviewingUser.name)
      );
    } else if (sortBy === 'nameDec') {
      sortedReviews.sort((a, b) =>
        b.reviewingUser.name.localeCompare(a.reviewingUser.name)
      );
    } else if (sortBy === 'dateAsc') {
      sortedReviews.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (sortBy === 'dateDec') {
      sortedReviews.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (sortBy === 'ratingAsc') {
      sortedReviews.sort((a, b) => new Date(a.rating) - new Date(b.rating));
    } else if (sortBy === 'ratingDec') {
      sortedReviews.sort((a, b) => new Date(b.rating) - new Date(a.rating));
    }

    return sortedReviews;
  };

  // Function to handle sort option change
  const handleSortChange = value => {
    setSortBy(value);
  };

  // Function to get sorted reviews
  const sortedReviews = filterAndSortReviews(reviews, sortBy);

  const addReview = async () => {
    if (!myComment) {
      toast.error('Please add review', {
        position: 'top-right',
      });
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        `${backendUrl}/course/review?courseId=${courseData._id}`,
        { rating: selectedStars, comment: myComment },
        config
      );
      // setReviews(response.data.reviews);
      setReviews(prevReviews => [...prevReviews, response.data.data]);
      setIsAddedReview(true);
      setMyReview(response.data.data);
      calculateStarCounts([...reviews, response.data.data]);
      toast.success('Review Added Successfully', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : 'Something Went Wrong',
        {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        }
      );
    }
  };

  const updateReview = async () => {
    if (!myComment) {
      toast.error('Please add review', {
        position: 'top-right',
      });
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.put(
        `${backendUrl}/course/review?reviewId=${myReview._id}`,
        { rating: selectedStars, comment: myComment },
        config
      );
      setReviews(prevReviews =>
        prevReviews.map(review => {
          if (review._id === myReview._id) {
            return {
              ...review,
              comment: myComment,
              rating: selectedStars,
            };
          }
          return review;
        })
      );

      const updatedReviews = () =>
        reviews.map(review => {
          if (review._id === myReview._id) {
            return {
              ...review,
              comment: myComment,
              rating: selectedStars,
            };
          }
          return review;
        });

      calculateStarCounts(updatedReviews());
      toast.success('Review Updated Successfully', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : 'Something Went Wrong',
        {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        }
      );
    }
  };

  const updateReviewByAdmin = async () => {
    if (!comment) {
      toast.error('Please add review', {
        position: 'top-right',
      });
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.put(
        `${backendUrl}/course/review?reviewId=${reviewToBeUpdated._id}`,
        { rating: selectedStarRating, comment: comment },
        config
      );
      setReviews(prevReviews =>
        prevReviews.map(review => {
          if (review._id === reviewToBeUpdated._id) {
            return {
              ...review,
              comment: comment,
              rating: selectedStarRating,
            };
          }
          return review;
        })
      );

      const updatedReviews = () =>
        reviews.map(review => {
          if (review._id === reviewToBeUpdated._id) {
            return {
              ...review,
              comment: comment,
              rating: selectedStarRating,
            };
          }
          return review;
        });

      calculateStarCounts(updatedReviews());
      toast.success('Review Updated Successfully', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : 'Something Went Wrong',
        {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        }
      );
    }
  };

  const deleteReview = async reviewId => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios.delete(
        `${backendUrl}/course/review?reviewId=${reviewId}`,
        config
      );
      setReviews(prevReviews =>
        prevReviews.filter(review => review._id !== reviewId)
      );
      calculateStarCounts(reviews.filter(review => review._id !== reviewId));
      toast.success('Review Deleted Successfully', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : 'Something Went Wrong',
        {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        }
      );
    }
  };

  useEffect(() => {
    // fetch reviews for this course
    const fetchReviews = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `${backendUrl}/course/review?courseId=${courseData._id}`,
          config
        );
        setReviews(response.data.reviews);

        if (getMyReviewIndex(response.data.reviews) != -1) {
          setMyReview(
            response.data.reviews[getMyReviewIndex(response.data.reviews)]
          );
          setMyComment(
            response.data.reviews[getMyReviewIndex(response.data.reviews)]
              .comment
          );
          setIsAddedReview(true);
          setSelectedStars(
            response.data.reviews[getMyReviewIndex(response.data.reviews)]
              .rating
          );
        }
        calculateStarCounts(response.data.reviews);
      } catch (error) {
        toast.error(
          error.response ? error.response.data.message : 'Something Went Wrong',
          {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          }
        );
      }
    };

    if (courseData._id) {
      fetchReviews();
    }
  }, [courseData, backendUrl, token]);

  const calculateStarCounts = reviews => {
    let fiveStarCount = 0;
    let fourStarCount = 0;
    let threeStarCount = 0;
    let twoStarCount = 0;
    let oneStarCount = 0;
    let totalRatings = 0;

    let totalNoRatings = reviews.length || 1;

    reviews.forEach(review => {
      totalRatings += review.rating;

      switch (review.rating) {
        case 5:
          fiveStarCount++;
          break;
        case 4:
          fourStarCount++;
          break;
        case 3:
          threeStarCount++;
          break;
        case 2:
          twoStarCount++;
          break;
        case 1:
          oneStarCount++;
          break;
        default:
          break;
      }
    });

    const overallRating = Number(totalRatings / totalNoRatings).toFixed(0);
    fiveStarCount = Number((fiveStarCount / totalNoRatings) * 100).toFixed(0);
    fourStarCount = Number((fourStarCount / totalNoRatings) * 100).toFixed(0);
    threeStarCount = Number((threeStarCount / totalNoRatings) * 100).toFixed(0);
    twoStarCount = Number((twoStarCount / totalNoRatings) * 100).toFixed(0);
    oneStarCount = Number((oneStarCount / totalNoRatings) * 100).toFixed(0);

    setOverallRating(overallRating);
    setStarCounts({
      fiveStarCount,
      fourStarCount,
      threeStarCount,
      twoStarCount,
      oneStarCount,
    });
  };

  const getMyReviewIndex = reviews => {
    const userId = localStorage.getItem('userId');
    for (let i = 0; i < reviews.length; i++) {
      if (reviews[i].reviewingUser._id.toString() === userId) {
        return i;
      }
    }

    return -1;
  };

  const getPercentage = index => {
    if (5 - index == 5) {
      return `${starCounts.fiveStarCount}%`;
    } else if (5 - index == 4) {
      return `${starCounts.fourStarCount}%`;
    } else if (5 - index == 3) {
      return `${starCounts.threeStarCount}%`;
    } else if (5 - index == 2) {
      return `${starCounts.twoStarCount}%`;
    } else if (5 - index == 1) {
      return `${starCounts.oneStarCount}%`;
    }
  };
  return (
    <>
      {courseData && (
        <div
          style={{
            height: '100%',
            overflowY: 'auto',
          
            boxSizing: 'border-box',
          }}
          p={{
        base:"0px",
        sm: "0px",
        md:"20px",
        lg:"20px"
      }}
        >
          <Heading as="h3" style={{ fontSize: '25px' }}>
            Student Feedback
          </Heading>
          <Flex>
            <Box
              display={'flex'}
              flexDirection={'column'}
              style={{ padding: '10px', color: '#B4690E' }}
            >
              <Text
                align={'center'}
                style={{
                  fontSize: '70px',
                  fontWeight: '700',
                  lineHeight: '60px',
                }}
              >
                {overallRating}
              </Text>
              <Rating
                selectedStars={overallRating}
                fontSize={'25px'}
                lineHeight={'20px'}
                interactive={false}
              />
              <Text
                textAlign={'center'}
                style={{ fontSize: '15px', fontWeight: '800' }}
              >
                Course Rating
              </Text>
            </Box>
            <Box
              style={{
                flexGrow: 1,
                padding: '10px',
              }}
            >
              <table style={{ width: '100%' }}>
                {[...Array(5)].map((_, index) => (
                  <tr key={index} style={{ display: 'flex' }}>
                    <td
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '20px',
                        justifyContent: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <div
                        style={{
                          flexGrow: 1,
                          width: '100%',
                          height: '8px',
                          background: 'gray',
                          position: 'relative',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            height: '100%',
                            background: 'black',
                            width: getPercentage(index),
                          }}
                        ></div>
                      </div>
                    </td>

                    <td>
                      <Rating
                        selectedStars={5 - index}
                        interactive={false}
                        fontSize={'20px'}
                        lineHeight={'10px'}
                        margin={'-10px 0px 0px 0px'}
                      />
                    </td>

                    <td
                      style={{
                        fontSize: '15px',
                        minWidth: '35px',
                        display: 'flex',
                      }}
                    >
                      {getPercentage(index)}
                    </td>
                  </tr>
                ))}
              </table>
            </Box>
          </Flex>
          <hr style={{ border: '0.3px solid lightgray' }} />
          {!isAdminViewing && (
            <>
              <Box padding={'20px 10px'}>
                <Heading
                  as="h3"
                  style={{ fontSize: '25px', marginBottom: '5px' }}
                >
                  {isAddedReview ? 'Update Your Review' : 'Add Your Review'}
                </Heading>
                <Flex justifyContent={"space-between"}>
                  <Textarea
   w={{
                    base:'52%',
                    sm:"52%",
                    md:"60%",
                    lg:'100%'
                  }}
                    placeholder="Write Your Feedback"
                    size="sm"
                    value={myComment}
                    onChange={e => {
                      setMyComment(e.target.value);
                    }}
                  />
                  <Box
                    minWidth={'20%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Rating
                      setSelectedStars={setSelectedStars}
                      selectedStars={selectedStars}
                      fontSize={'30px'}
                      lineHeight={'20px'}
                    />
                    <Button
                      colorScheme="blue"
                      onClick={isAddedReview ? updateReview : addReview}
                    >
                      {isAddedReview ? 'Update' : 'Submit'}
                    </Button>
                  </Box>
                </Flex>
              </Box>
              <hr style={{ border: '0.3px solid lightgray' }} />{' '}
            </>
          )}

          <Box padding={'20px 10px'}>
            <Flex justifyContent={'space-between'}>
              <Heading as="h3" style={{ fontSize: '25px' }}>
                Reviews
              </Heading>
              <Menu>
                <MenuButton as={Button} rightIcon={<BiSort />}>
                  Sort
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    defaultValue="dateAsc"
                    type="radio"
                    onChange={value => {
                      handleSortChange(value);
                    }}
                  >
                    <MenuItemOption value={'nameAsc'}>
                      By Name (Ascendeing)
                    </MenuItemOption>
                    <MenuItemOption value={'nameDec'}>
                      By Name (Descending)
                    </MenuItemOption>
                    <MenuItemOption value={'dateAsc'}>
                      By Date (Ascendeing)
                    </MenuItemOption>
                    <MenuItemOption value={'dateDec'}>
                      By Date (Descending)
                    </MenuItemOption>
                    <MenuItemOption value={'ratingAsc'}>
                      By Rating (Ascendeing)
                    </MenuItemOption>
                    <MenuItemOption value={'ratingDec'}>
                      By Rating (Descending)
                    </MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Flex>

            {sortedReviews.map((review, i) => (
              <div
                style={{
                  display: 'flex',
                  padding: '10px',
                }}
                key={i}
                className="hover"
              >
                <Avatar
                  size="md"
                  name={review.reviewingUser.name}
                  src={`${backendUrl}${review.reviewingUser.dp}`}
                  style={{ background: 'black', color: 'white' }}
                />
                <div style={{ marginLeft: '20px', flexGrow: 1 }}>
                  <Flex justify={'space-between'}>
                    <div>
                      <Text style={{ fontWeight: 700, fontSize: '17px' }}>
                        {review.reviewingUser.name}
                      </Text>
                      <Rating
                        selectedStars={review.rating}
                        interactive={false}
                        fontSize={'23px'}
                        lineHeight={'10px'}
                        margin={'-5px 0px 0px -7px'}
                      />
                    </div>
                    <Menu placement="auto-end">
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HamburgerIcon />}
                        variant="outline"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => {
                            setReviewToBeUpdated(review);
                            setComment(review.comment);
                            setSelectedStarRating(review.rating);
                            onOpen();
                          }}
                        >
                          Edit Review...
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon />}
                          onClick={() => {
                            deleteReview(review._id);
                          }}
                        >
                          Delete Review...
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                  <Text style={{ fontSize: '15px' }}>{review.comment}</Text>
                  <Text
                    style={{ fontSize: '12px', fontWeight: 500, color: 'gray' }}
                  >
                    {getTimeAgoString(review.createdAt)}
                  </Text>
                </div>
              </div>
            ))}
          </Box>

          {isAdminViewing && reviewToBeUpdated && (
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  Update {reviewToBeUpdated.reviewingUser.name}'s Review
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
               
                    placeholder="Write Your Feedback"
                    size="sm"
                    value={comment}
                    onChange={e => {
                      setComment(e.target.value);
                    }}
                  />
                  <Rating
                    setSelectedStars={setSelectedStarRating}
                    selectedStars={selectedStarRating}
                    fontSize={'30px'}
                    lineHeight={'20px'}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      updateReviewByAdmin();
                      onClose();
                    }}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default Review;
