import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/AuthContext';
import {
  HStack,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import CourseContext from '../../../context/CourseListContext';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const HandleCourse = () => {
  const { backendUrl } = useContext(EnvContext);
  const { userData, handleUserData } = useContext(AuthContext);
  const { fetchData, courses } = useContext(CourseContext);
  const [showCourses, setShowCourses] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userData && Array.isArray(userData.courses)) {
      const assignedCourses = userData.courses.map(el => el.course.courseName);

      // Filter out assigned courses from all courses
      const unassignedCourses = courses.filter(
        course => !assignedCourses.includes(course.courseName)
      );

      setShowCourses(unassignedCourses);
    }
  }, [courses, userData]);

  useEffect(() => {
    if (showCourses) console.log('showCourses', showCourses);
  }, [showCourses]);
  const formatDate = dateString => {
    if (!dateString) return ''; // Return empty string if date string is not provided

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    // Get the date in YYYY-MM-DD format
    return date.toISOString().split('T')[0];
  };
  useEffect(() => {
    if (userData) {
      console.log('userData', userData);
    }
  }, [userData]);
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  const addCourseManually = async (courseId,courseName) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    const confirmed = window.confirm('Are you sure to add this '+courseName+' to "'+userData.name+'" ?');
  
  // Check if user confirmed
  if (!confirmed) return;
    try {
      loadingToast = toast.loading('Adding course....', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .post(
          `${backendUrl}/user/course?courseId=${courseId}&userId=${userData._id}`,
          null,
          { headers: headers }
        )
        .then(res => {
          console.log('addCourseManually', res.data);
          // setCourse(res.data.courses);
          handleUserData(userData._id);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const deleteCourseManually = async (courseId,courseName) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    const confirmed = window.confirm('Are you sure to delete this '+courseName+' to "'+userData.name+'" ?');
  
  // Check if user confirmed
  if (!confirmed) return;
    try {
      loadingToast = toast.loading('Deleteing course....', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .delete(
          `${backendUrl}/user/course?courseId=${courseId}&userId=${userData._id}`,
         
          { headers: headers }
        )
        .then(res => {
          console.log('deleteCourseManually', res.data);
          // setCourse(res.data.courses);
          handleUserData(userData._id);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        })
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <>
      <HStack w={'100%'} gap={'0.5rem'} alignItems={'flex-start'}>
        <VStack w={'60%'} borderRight={'1px solid'} borderColor={'gray.100'}>
          <Text fontWeight={700} fontSize={'large'}>
            Active Courses
          </Text>
          <TableContainer width={'90%'}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th w={'3rem'}>Sl. No.</Th>
                  <Th w={'60%'}>Course Name</Th>
                  <Th>Started Date</Th>
                  <Th>Delete</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userData &&
                  userData.courses &&
                  Array.isArray(userData.courses) &&
                  userData.courses.length > 0 &&
                  userData.courses.map((el, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{el?.course?.courseName}</Td>
                      <Td>{formatDate(el.addedDate)}</Td>
                      <Td>
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Delete"
                          colorScheme="red"
                          ml={2}
                          onClick={()=>deleteCourseManually(el?.course?._id,el?.course?.courseName)}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>

        <VStack w={'40%'}>
          <Text fontWeight={700} fontSize={'large'}>
            Add Courses
          </Text>
          <VStack width={'100%'}>
            {showCourses &&
              Array.isArray(showCourses) &&
              showCourses.length > 0 &&
              showCourses.map((el, i) => (
                <HStack
                  width={'100%'}
                  key={i}
                  justify={'space-between'}
                  px={'10rem'}
                >
                  <Text>
                    {i + 1}) {el.courseName}
                  </Text>

                  <IconButton
                    icon={<AddIcon />}
                    aria-label="add"
                    colorScheme="gray"
                    ml={2}
                    onClick={() => addCourseManually(el._id,el.courseName)}
                  />
                </HStack>
              ))}
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};

export default HandleCourse;
