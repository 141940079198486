import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,

} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import AssignmentContext from '../../../context/AssignmentContext';
import { Quizes } from '../../../pages/user/authentication/quiz/Quizes';

export const QuizModal = ({ children, courseContentId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { getAllQuestions, questions, prevAttempts } =
    useContext(AssignmentContext);
  // useEffect(() => {
  //   if (questions) console.log('questions', questions);
  // }, [questions]);

  useEffect(() => {
    if (courseContentId) {
      getAllQuestions(courseContentId);
      console.log('courseContentId', courseContentId);
    }
  }, [courseContentId]);
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={{ base: 'xl', md: 'full', lg: 'full' }}
        // height={"60vh"} width={"80%"}
        // scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Attempt Quiz ({questions && questions.length})</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY={'none'}>
            <HStack spacing={10} w={"100%"}>
              <Box w={"100%"} position={"relative"}>
                <Tabs height={'100%'} w={"100%"}>
                  <TabList>
                    <Tab>Quiz</Tab>
                    <Tab>Submissions</Tab>
                  </TabList>

                  <TabPanels height={'100%'} >
                    <TabPanel height={'100%'} >
                      <Quizes />
                    </TabPanel>
                    <TabPanel height={'100%'} >
                      {/* {Array.isArray(prevAttempts) &&
                        prevAttempts.map((el, i) => (
                          <>
                            {Array.isArray(el.answer) &&
                              el.answer.length > 0 &&
                              el.answer.map((el, i) =>
                              
                               <li key={i}>{el}</li>)}
                            <hr />
                          </>
                        ))} */}
                      <Table size="md">
                        <Thead bgColor={"blue.100"}>
                          <Tr>

                            <Th>Answer</Th>
                            <Th>Created At</Th>
                            <Th>Is Correct</Th>

                          </Tr>
                        </Thead>
                        <Tbody>
                          {
                            Array.isArray(prevAttempts) &&
                            prevAttempts.map((item, index) => (
                              <Tr key={index}>

                                <Td>
                                  <ul>
                                    {item.answer.map((ans, idx) => (
                                      <li key={idx}>{ans}</li>
                                    ))}
                                  </ul>
                                </Td>
                                <Td>{new Date(item.createdAt).toLocaleString()}</Td>
                                <Td>{item.isCorrectAnswer ? 'Yes' : 'No'}</Td>
                              </Tr>
                            ))
                          }
                        </Tbody>
                      </Table>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
