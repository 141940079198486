import {
  Flex,
  Heading,
  VStack,
  Box,
  Button,
  Text,
  Tooltip,
  Stack,
  InputGroup,
  Input,
  InputLeftElement,
  useMediaQuery,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ShowCourseCard } from '../../../../components/elements/ShowCourseCard';
import UserDashboardSidebar from '../../../../components/layouts/UserDashboardSidebar';
import AuthContext from '../../../../context/AuthContext';
import EnvContext from '../../../../context/EnvContext';
import CartContext from '../../../../context/CartContext';
import { FaAngleDoubleRight } from 'react-icons/fa';

import 'react-calendar/dist/Calendar.css';
import { ReadingStreak } from './ReadingStreak';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CourseContext from '../../../../context/CourseListContext';
import ContentContext from '../../../../context/ContentContext';
import debounce from 'lodash.debounce';
import { SearchIcon } from '@chakra-ui/icons';
import useSaveLastRoute from '../../../../hooks/useSaveLastRoute';
import DashboardTour from '../../../../tour/DashboardTour';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { Header } from '../../../../components/layouts/Header';
import SubscriptionPlanCard from './SubscriptionPlanCard';

export const UserDashboard = ({ cardValues }) => {
  useSaveLastRoute();
  const navigate = useNavigate();
  const { backendUrl } = useContext(EnvContext);
  const { userData } = useContext(AuthContext);
  const { getcart } = useContext(CartContext);
  const { setCourseId } = useContext(CourseContext);
  const { fetchEncryptedContent, setContentId } = useContext(ContentContext);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const unAuthItem = localStorage.getItem('unAuthCartItems');
  const [currentReadingStatus, setCurrentReadingStatus] = useState({});
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredSubscription, setFilteredSubscription] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [showPopup, setShowPopup] = useState(false);
  const [startTours, setStartTours] = useState(false);
  const searchRef = useRef(null);
  useEffect(() => {
    if (userData) {
      console.log('userData', userData);
      setFilteredCourses(userData.courses);
      setFilteredSubscription(userData.subscriptions);
      if (userData.dashboardPageTour === true) {
        console.log('userData.dashboardPageTour', userData.dashboardPageTour);
        setShowPopup(true);
      }
    }
  }, [userData]);

  const handleSearch = value => {
    console.log('value', value);
    const filtered = userData?.courses.filter(el =>
      el?.course?.courseName.toLowerCase().includes(value.toLowerCase())
    );

    console.log('filtered', filtered);
    setFilteredCourses(filtered);
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 300),
    [cardValues]
  );
  const handleInputChange = event => {
    debouncedHandleSearch(event.target.value);
  };

  useEffect(() => {
    const getCurrentReadingStatus = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `${backendUrl}/user/rs/curr_status?userId=${userId}`,
          config
        );
        setCurrentReadingStatus(response.data);
      } catch (error) {
        // toast.error(
        //   error.response ? error.response.data.message : 'Something Went Wrong',
        //   {
        //     position: 'top-right',
        //     style: {
        //       marginTop: '62px',
        //     },
        //   }
        // );
      }
    };
    getCurrentReadingStatus();
  }, []);
  useEffect(() => {
    if (token && !unAuthItem) {
      console.log('token available, nothing in the auth cart local storage');
      getcart();
    }
  }, []);
  useEffect(() => {
    if (currentReadingStatus)
      console.log('currentReadingStatus', currentReadingStatus);
  }, [currentReadingStatus]);
  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: '#step1',
        popover: {
          title: 'Profile',
          description: 'Here you can see your profile Details',
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#step2',
        popover: {
          title: 'Bio',
          description:
            'Here you can add your bio details. If you have already added your bio details then you can update it from here.',
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#step3',
        popover: {
          title: 'Transaction ',
          description: 'Here You can see all your transctions.',
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#step4',
        popover: {
          title: 'Settings',
          description:
            ' Here you can update your profile,change your password or delete your profile.',
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#step5',
        popover: {
          title: 'Yopur Learnings ',
          description:
            'Here you can see all your enrolled courses and subscription ',
          side: 'top',
          align: 'start',
        },
      },
      {
        element: '#step6',
        popover: {
          title: 'Resume Learning',
          description: 'Here you can see your last seen content',
          side: 'right',
          align: 'start',
        },
      },
      {
        element: '#step7',
        popover: {
          title: 'Reading Streak',
          description: 'Here you can see your dat to day activities',
          side: 'right',
          align: 'start',
        },
      },
      {
        popover: {
          title: 'Happy Learning',
          description: 'Thank you for the touring .',
        },
      },
    ],
  });
  const startTour = () => {
    driverObj.drive();
  };
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (filteredSubscription?.length > 0 && filteredCourses?.length === 0) {
      setTabIndex(1); // Set tabIndex to 1 for Subscriptions tab
    } else {
      setTabIndex(0); // Set tabIndex to 0 for Courses tab
    }
  }, [filteredSubscription, filteredCourses]);
  return (
    <>
      <Header startTour={startTour} />
      <Flex
        className="userHome"
        flexDirection={'row'}
        alignItems={'flex-start'}
        margin={'auto'}
        marginTop={10}
        justifyContent={'center'}
      >
        <UserDashboardSidebar
          elementId="#step1"
          elementId1="#step2"
          elementId2="step3"
          elementId3="step4"
          elementId4="step5"
        />
        <VStack
          width={{
            base: '100%',
            sm: '100%',
            md: '48%',
            lg: '60%',
          }}
        >
          <Flex
            width={'100%'}
            position={'sticky'}
            bg={'white'}
            margin={{
              base: 0,
              lg: '0.75rem',
            }}
            padding={3}
            marginBottom={0}
            paddingBottom={7}
            paddingTop={4}
            top={0}
            zIndex={1}
            justifyContent={'space-between'}
          >
            <Heading
              pl={5}
              fontSize={'4xl'}
              lineHeight={{
                base: '2.2rem',
                lg: '1.2rem',
              }}
              bg={'white'}
            >
              Your Learnings
            </Heading>

            <Stack spacing={4}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon />
                </InputLeftElement>
                <Input
                  ref={searchRef}
                  type="text"
                  placeholder="Search Courses"
                  bg="white"
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Stack>
          </Flex>

          <Tabs
            id="step5"
            index={tabIndex}
            onChange={handleTabsChange}
            isFitted
            variant="enclosed"
            width={'100%'}
          >
            <TabList mb="1em">
              <Tab
                borderTopRightRadius={99}
                borderTopLeftRadius={99}
                bg={tabIndex === 0 ? '#a7d3eb4f' : ''}
                color={'black'}
                fontSize={'large'}
              >
                Courses
              </Tab>
              <Tab
                borderTopRightRadius={99}
                borderTopLeftRadius={99}
                bg={tabIndex === 1 ? '#a7d3eb4f' : ''}
                color={'black'}
                fontSize={'large'}
              >
                Subscriptions
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex width={'100%'} flexDirection="row" flexWrap="wrap" p={5}>
                  {filteredCourses && filteredCourses?.length > 0 ? (
                    filteredCourses?.map((item, i) => (
                      <ShowCourseCard
                        key={i}
                        heading={item.course?.courseName}
                        img={`${item.course?.banner}`}
                        language={item.course?.language}
                        index={i}
                        id={item.course?._id}
                      />
                    ))
                  ) : (
                    <VStack
                      alignItems={'center'}
                      gap={'5rem'}
                      px={20}
                      py={10}
                      width={'100%'}
                    >
                      {searchRef.current && searchRef.current.value ? (
                        <Text fontWeight={'600'} fontSize={'xl'}>
                          You dont have such type of course
                        </Text>
                      ) : (
                        <Text fontWeight={'600'} fontSize={'xl'}>
                          You are not enrolled in any course
                        </Text>
                      )}

                      <Button
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => {
                          navigate('/products');
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          });
                        }}
                      >
                        Checkout Our New Courses
                      </Button>
                    </VStack>
                  )}
                </Flex>
              </TabPanel>

              <TabPanel>
                <Flex
                  width={'100%'}
                  flexDirection="row"
                  flexWrap="wrap"
                  p={5}
                  id="step5"
                  gap={5}
                >
                  {filteredSubscription && filteredSubscription?.length > 0 ? (
                    filteredSubscription?.map((item, i) => (
                      <SubscriptionPlanCard plan={item} />
                    ))
                  ) : (
                    <VStack alignItems={'center'} gap={'5rem'} px={20} py={10}>
                      <Text fontWeight={'600'} fontSize={'xl'}>
                        You are not enrolled in any subscription
                      </Text>

                      <Button
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => {
                          navigate('/subscription_plans');
                          window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          });
                        }}
                      >
                        Checkout Our New Subscription plans
                      </Button>
                    </VStack>
                  )}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>{' '}
        {isMobile ? (
          ''
        ) : (
          <Flex
            width={'20%'}
            position={'sticky'}
            top={0}
            direction={'column'}
            padding={'10px'}
            boxSizing="border-box"
          >
            <div style={{ background: 'white', position: 'sticky', top: 0 }}>
              {currentReadingStatus.success && (
                <>
                  <Box
                    onClick={() => {
                      console.log('reume learning***********');
                    }}
                    cursor={'pointer'}
                    style={{
                      boxShadow:
                        '-2px 0px 6px rgba(0, 0, 0, 0.04), -2px 0px 15px rgba(0, 0, 0, 0.04)',
                      borderRadius: '15px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginBottom: '10px',
                      padding: '10px',
                    }}
                  >
                    <Heading as="h4" size="md" id="step6">
                      Resume Learning
                    </Heading>
                    <div
                      style={{ paddingLeft: '10px' }}
                      onClick={() => {
                        setCourseId(
                          currentReadingStatus?.data?.currentCourse?._id
                        );
                        navigate('/video_page');
                        fetchEncryptedContent(
                          currentReadingStatus?.data?.currentContent?._id
                        );
                        setContentId(
                          currentReadingStatus?.data?.currentContent?._id
                        );
                      }}
                    >
                      <Text
                        fontSize="sm"
                        fontWeight={'600'}
                        color={'gray'}
                        display={'flex'}
                        alignItems={'center'}
                        gap={'5px'}
                        flexWrap={'wrap'}
                      >
                        {currentReadingStatus?.data?.currentCourse?.courseName}
                        <FaAngleDoubleRight style={{ display: 'inline' }} />
                        {currentReadingStatus?.data?.currentContent?.title}
                      </Text>
                    </div>
                  </Box>
                  <hr />
                </>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  justifyContent: 'center',
                }}
              >
                <Heading as="h4" size="md" textAlign={'center'} id="step7">
                  Reading Streak
                </Heading>
                <Tooltip
                  hasArrow
                  label={
                    <div>
                      <table>
                        <tr>
                          <td width={'10px'}>
                            <div
                              style={{
                                height: '5px',
                                width: '5px',
                                background: 'red',
                                borderRadius: '50%',
                              }}
                            ></div>
                          </td>
                          <td>
                            <Text fontSize="sm">Watched Content</Text>
                          </td>
                        </tr>
                        <tr>
                          <td width={'10px'}>
                            <div
                              style={{
                                height: '5px',
                                width: '5px',
                                background: 'green',
                                borderRadius: '50%',
                              }}
                            ></div>
                          </td>
                          <td>
                            <Text fontSize="sm">Completed Assignment</Text>
                          </td>
                        </tr>
                        <tr>
                          <td width={'10px'}>
                            <div
                              style={{
                                height: '5px',
                                width: '5px',
                                background: 'blue',
                                borderRadius: '50%',
                              }}
                            ></div>
                          </td>
                          <td>
                            <Text fontSize="sm">Completed Project</Text>
                          </td>
                        </tr>
                      </table>
                    </div>
                  }
                  bg="gray.300"
                  color="black"
                >
                  <div
                    style={{
                      border: '2px solid gray',
                      color: 'gray',
                      height: '15px',
                      width: '15px',
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '10px',
                      fontWeight: '600',
                      cursor: 'help',
                    }}
                  >
                    i
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              <ReadingStreak />
            </div>
          </Flex>
        )}
        {showPopup && (
          <DashboardTour
            setShowPopup={setShowPopup}
            setStartTours={setStartTours}
            startTour={startTour}
          />
        )}
      </Flex>
    </>
  );
};
