import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  lazy,
  Suspense,
} from 'react';
import {
  VStack,
  HStack,
  Text,
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { video } from '../../../../assets/video';
import { VideoList } from './VideoList';
import ContentContext from '../../../../context/ContentContext';
import CourseContext from '../../../../context/CourseListContext';
import EnvContext from '../../../../context/EnvContext';

import useSaveLastRoute from '../../../../hooks/useSaveLastRoute';
import { FaThumbsUp } from 'react-icons/fa';
import { css, keyframes } from '@emotion/react';
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import LearningPageTour from '../../../../tour/LearningPageTour';
import AuthContext from '../../../../context/AuthContext';
import { UserHeader } from '../../../../components/layouts/UserHeader';

const LeaderBoard = lazy(() => import('./LeaderBoard'));
const Overview = lazy(() => import('./Overview'));
const Review = lazy(() => import('./Review'));
const UserProject = lazy(() => import('../UserProject'));
const UserAnalystTool = lazy(() => import('../UserAnalystTool'));

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

function isUserLiked(courseContent, courseId, userId) {
  // Check if courseContent is defined and is an array
  if (!Array.isArray(courseContent)) {
    return false;
  }

  // Find the course object with the given courseId
  const course = courseContent.find(
    course => course && course._id === courseId
  );

  // If course is not found or likes array is undefined, return false
  if (!course || !Array.isArray(course.likes)) {
    return false;
  }

  // Check if the user is present in the likes array
  const userLiked = course.likes.some(user => user && user._id === userId);

  return userLiked;
}

function getLikesCount(courseContent, courseId) {
  // Check if courseContent is defined and is an array
  if (!Array.isArray(courseContent)) {
    return 0;
  }

  // Find the course object with the given courseId
  const course = courseContent.find(
    course => course && course._id === courseId
  );

  // If course is not found or likes array is undefined, return 0
  if (!course || !Array.isArray(course.likes)) {
    return 0;
  }

  // Return the length of the likes array
  return course.likes.length;
}
export const VideoPage = () => {
  useSaveLastRoute();
  const {
    fetchAllContent,
    decryptedVideo,
    contentId,
    addReadingStatus,
    lastWatchedPoint,
    videoDuration,
    markAsRead,
    fetchUserScoreboard,
    scorecard,
    decryptedText,
    fetchUserCurrentReadingStatus,
    handleToggleLike,
    setContentId,
    setVideoDuration,
    setDecryptedText,
    setDecryptedVideo,
    fetchEncryptedContent,
    contents,
  } = useContext(ContentContext);
  const { courseData, courseId, getCourse } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const userId = localStorage.getItem('userId');
  const [watchedDuration, setWatchedDuration] = useState(0);
  const [apiCalled, setApiCalled] = useState(0);
  const token = localStorage.getItem('token');
  const { userData, handleUserData } = useContext(AuthContext);
  const videoRef = useRef(null);
  const badgeRef = useRef(null);
  const [isBlackScreen, setIsBlackScreen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [showLike, setShowLike] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showEmail, setShowEmail] = useState(false);
  const [emailPosition, setEmailPosition] = useState({
    top: '0px',
    left: '0px',
  });

  useEffect(() => {
    handleUserData();
  }, []);
  useEffect(() => {
    if (courseData) {
      console.log('userData', userData);

      if (userData.learningPageTour === true) {
        console.log('userData.videopageTour', userData.dashboardPageTour);
        setShowPopup(true);
      }
    }
  }, [courseData]);

  const handlePause = () => {
    setIsPaused(true);
  };
  const handlePlay = () => {
    setIsPaused(false);
  };

  //  useEffect(() => {
  //   const count = getLikesCount(courseData?.courseContent, contentId);
  //   setLikeCount(count);
  //   const likedVideo = isUserLiked(
  //     courseData?.courseContent,
  //     contentId,
  //     userId
  //   );
  //   setLiked(likedVideo);
  // }, [contentId]);

  // const handleLike = () => {
  //   if (liked) return;
  //   if (!liked) handleToggleLike();

  //   setLiked(true);
  //   setLikeCount(prevCount => prevCount + 1);
  // };

  useEffect(() => {
    const fetchLikesData = async () => {
      const count = await getLikesCount(courseData?.courseContent, contentId);
      setLikeCount(count);
      const likedVideo = await isUserLiked(
        courseData?.courseContent,
        contentId,
        userId
      );
      setLiked(likedVideo);
    };

    fetchLikesData();
  }, [contentId]);

  const handleLike = async () => {
    if (liked) return;

    // Assuming handleToggleLike updates the backend
    await handleToggleLike(courseData?.courseContent, contentId, userId);

    setLiked(true);
    setLikeCount(prevCount => prevCount + 1);
  };

  const addReadingStatusAPI = () => {
    const postData = {
      courseId: courseId,
      userId: userId,
      contentId: contentId,
      pausedTime: watchedDuration,
    };

    // console.log('video reading status', postData);
    addReadingStatus(postData);
  };

  useEffect(() => {
    getCourse();
  }, [userId]);

  useEffect(() => {
    if (courseId) {
      fetchAllContent();
      fetchUserScoreboard(userId, courseId);
    }
  }, [courseId]);
  useEffect(() => {
    if (watchedDuration) {
      addReadingStatusAPI();
      handleProgress();
    }
  }, [watchedDuration]);

  useEffect(() => {
    if (isPaused) {
      addReadingStatusAPI();
      handleProgress();
      startTracking();
    }
  }, [isPaused]);

  useEffect(() => {
    if (decryptedVideo) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [decryptedVideo]);

  useEffect(() => {
    if (decryptedText) {
      // console.log('decryptedText', decryptedText);
      setShowVideo(true);
      markAsRead(userId, courseId, contentId);
    } else {
      setShowVideo(false);
    }
  }, [decryptedText]);
  let intervalId;
  const startTracking = () => {
    intervalId = setInterval(() => {
      if (videoRef.current && videoRef.current.currentTime) {
        const currentTime = Math.floor(videoRef.current.currentTime); // C
        setWatchedDuration(currentTime);
      }
    }, 30000); // Check every 10 seconds
  };
  useEffect(() => {
    fetchUserCurrentReadingStatus(userId);

    // Start tracking the video duration

    startTracking();
    // Stop tracking when component unmounts
    return () => {
      clearInterval(intervalId);
    };

    // Start tracking when component mounts
  }, []); // Empty dependency array to run effect only once

  function getSecondsFromDuration(videoDuration) {
    const splitDuration = videoDuration?.split(':'); // Split the string by colon
    const minutes = parseInt(splitDuration[0]); // Access the minutes part and convert it to an integer
    const seconds = parseInt(splitDuration[1]); // Access the seconds part and convert it to an integer

    // Convert minutes to seconds and add with seconds
    const totalSeconds = minutes * 60 + seconds;

    return totalSeconds;
  }
  // useEffect(() => {
  //   if (videoDuration) {
  //     // console.log('watchedDuration', watchedDuration);
  //     // console.log(
  //     //   'videoDuration',
  //     //   videoDuration,
  //     //   'parsed',
  //     //   getSecondsFromDuration(videoDuration)
  //     // );
  //     // console.log(
  //     //   'videoDuration 95%',
  //     //   getSecondsFromDuration(videoDuration) * 0.95
  //     // );
  //   }
  // }, [videoDuration]);
  // Function to handle video progress
  const handleProgress = () => {
    // console.log("video cureent time",videoRef.current.currentTime,"95%", getSecondsFromDuration(videoDuration) * 0.95,"apiCalled",apiCalled)
    if (
      videoRef?.current?.currentTime >=
        getSecondsFromDuration(videoDuration) * 0.8 &&
      !apiCalled
    ) {
      setShowLike(true);
      // Call your API here
      console.log(' API... at 80%');
      if (apiCalled === 0) {
        console.log('aysgvaiysgva', apiCalled);
        markAsRead(userId, courseId, contentId);
        setApiCalled(1);
      }
      setApiCalled(1);
    } else {
      console.log(
        'apiCalled',
        apiCalled,
        'video',
        videoRef?.current?.currentTime,
        'videoDuration',
        getSecondsFromDuration(videoDuration)
      );
    }
  };
  const startTour = () => {
    driverObj.drive();
  };
  const driverObj = driver({
    showProgress: true,
    steps: [
      {
        element: '#step1',
        popover: {
          title: 'Video',
          description: 'Here you can see your course video',
          side: 'left',
          align: 'start',
        },
      },
      {
        element: '#step2',
        popover: {
          title: 'Courses',
          description:
            'Here you can see the course content, including assigned assignments and notes.',
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#step3',
        popover: {
          title: 'All details ',
          description:
            'Here you can see all the details, such as the course overview, assigned projects, progress in the course, leaderboard, and reviews.',
          side: 'bottom',
          align: 'start',
        },
      },
      {
        popover: {
          title: 'Happy Learning',
          description: 'Thank you for the touring .',
        },
      },
    ],
  });
  // useEffect(() => {
  //   const handleContextMenu = e => {
  //     e.preventDefault();
  //   };
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       setIsBlackScreen(true);
  //     } else {
  //       setIsBlackScreen(false);
  //     }
  //   };
  //   // console.log("lastWatchedPoint",lastWatchedPoint,"showVideo",showVideo,"videoRef",videoRef,"watchedDuration",watchedDuration,"videoDuration",videoDuration)
  //   console.log('lastWatchedPoint', parseInt(lastWatchedPoint, 10));
  //   const handleMetadataLoaded = () => {
  //     // Start the video from the last watched point
  //     if (videoRef.current) {
  //       const lastWatchedPointNumber = parseInt(lastWatchedPoint, 10); // Convert to a number
  //       videoRef.current.currentTime = lastWatchedPointNumber;
  //     }
  //   };

  //   if (showVideo && videoRef.current && lastWatchedPoint && !apiCalled) {
  //     document.addEventListener('visibilitychange', handleVisibilityChange);
  //     videoRef.current.addEventListener('contextmenu', handleContextMenu);
  //     videoRef.current.addEventListener('loadedmetadata', handleMetadataLoaded);
  //     videoRef.current.addEventListener('timeupdate', handleProgress);
  //     videoRef.current.addEventListener('pause', handlePause); // Add event listener for pause
  //     videoRef.current.addEventListener('play', handlePlay); // Add event listener for play
  //     return () => {
  //       if (videoRef.current) {
  //         videoRef.current.removeEventListener(
  //           'loadedmetadata',
  //           handleMetadataLoaded
  //         );
  //         document.removeEventListener(
  //           'visibilitychange',
  //           handleVisibilityChange
  //         );
  //         videoRef.current.removeEventListener(
  //           'contextmenu',
  //           handleContextMenu
  //         );
  //         videoRef.current.removeEventListener('timeupdate', handleProgress);
  //         videoRef.current.removeEventListener('pause', handlePause); // Remove event listener for pause
  //         videoRef.current.removeEventListener('play', handlePlay); // Remove event listener for play
  //       }
  //     };
  //   }
  // }, [
  //   lastWatchedPoint,
  //   showVideo,
  //   videoRef,
  //   watchedDuration,
  //   videoDuration,
  //   apiCalled,
  // ]);

  useEffect(() => {
    const handleContextMenu = e => {
      e.preventDefault();
    };
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsBlackScreen(true);
      } else {
        setIsBlackScreen(false);
      }
    };
    const handleMetadataLoaded = () => {
      if (videoRef.current) {
        const lastWatchedPointNumber = parseInt(lastWatchedPoint, 10);
        videoRef.current.currentTime = lastWatchedPointNumber;
      }
    };

    if (showVideo && videoRef.current && lastWatchedPoint) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      videoRef.current.addEventListener('contextmenu', handleContextMenu);
      videoRef.current.addEventListener('loadedmetadata', handleMetadataLoaded);
      videoRef.current.addEventListener('timeupdate', handleProgress);
      videoRef.current.addEventListener('pause', handlePause);
      videoRef.current.addEventListener('play', handlePlay);
      setShowEmail(true);
      const emailInterval = setInterval(() => {
        const randomTop =
          Math.random() * (videoRef.current.clientHeight - 50) + 'px'; // Random top position
        const randomLeft =
          Math.random() * (videoRef.current.clientWidth - 200) + 'px'; // Random left position
        setEmailPosition({ top: randomTop, left: randomLeft });
        // setTimeout(() => setShowEmail(false), 5000);
      }, 20000);

      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener(
            'loadedmetadata',
            handleMetadataLoaded
          );
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange
          );
          videoRef.current.removeEventListener(
            'contextmenu',
            handleContextMenu
          );
          videoRef.current.removeEventListener('timeupdate', handleProgress);
          videoRef.current.removeEventListener('pause', handlePause);
          videoRef.current.removeEventListener('play', handlePlay);
        }
        clearInterval(emailInterval);
      };
    }
  }, [
    lastWatchedPoint,
    showVideo,
    videoRef,
    watchedDuration,
    videoDuration,
    apiCalled,
    handleProgress,
    handlePause,
    handlePlay,
  ]);

  // Ensure effect runs when decryptedVideo changes

  useEffect(() => {
    if (!decryptedText) {
      if (scorecard && scorecard?.scoreCard?.lastContentWatched) {
        const targetId = scorecard?.scoreCard?.lastContentWatched;
        console.log('targetId', targetId);
        fetchEncryptedContent(targetId);
        setContentId(targetId);
        setApiCalled(0);
        setDecryptedText('');
        setDecryptedVideo('');
        if (contents && contents.length > 0) {
          const videoDuration = contents.find(
            content => content._id === targetId
          )?.videoDuration;
          setVideoDuration(videoDuration);
        }
      } else {
        if (contents && contents.length > 0) {
          fetchEncryptedContent(contents[0]._id);
          setContentId(contents[0]._id);
          setVideoDuration(contents[0].videoDuration);
          setApiCalled(0);
          setDecryptedText('');
          setDecryptedVideo('');
        }
      }
    }
  }, [scorecard]);

  useEffect(() => {
    console.log('decryptedVideo', decryptedVideo);
  }, [decryptedVideo]);

  useEffect(() => {
    const savedIndex = localStorage.getItem('tabIndex');
    if (savedIndex) {
      setTabIndex(parseInt(savedIndex, 10));
    }
  }, []);

  const handleTabsChange = index => {
    setTabIndex(index);
    localStorage.setItem('tabIndex', index);
  };

  return (
    <>
      <UserHeader startTour={startTour} courseData={courseData} />

      <Flex
        flexDirection={{
          base: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
        }}
        height={{
          base: '78vh',
          sm: '78vh',
          md: '85vh',
          lg: '81vh',
        }}
      >
        <Box
          flex={{
            base: '7%',
            md: '5%',
            lg: '75%',
          }}
          height={'100%'}
          position={'relative'}
        >
          <div
            id="step1"
            style={{
              width: '100%',
              height: '100%',
              // height: '-webkit-fill-available',
              backgroundColor: 'black',
            }}
          >
            {showVideo ? (
              decryptedVideo ? (
                <>
                  <video
                    key={decryptedVideo}
                    controls
                    style={{ width: '100%', height: '100%' }}
                    ref={videoRef}
                    controlsList="nodownload"
                  >
                    <source
                      src={`${backendUrl}${decryptedVideo}`}
                      type="video/mp4"
                    />
                  </video>
                  {showEmail && (
                    <Box
                      color={'gray.100'}
                      style={{
                        position: 'absolute',
                        top: emailPosition.top,
                        left: emailPosition.left,
                        padding: '10px',
                        borderRadius: '5px',
                        zIndex: 2,
                        opacity: 0.4,
                      }}
                    >
                      {userData?.email} / SOLP
                    </Box>
                  )}
                </>
              ) : (
                <embed
                  src={`${backendUrl}${decryptedText}`}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                />
              )
            ) : (
              <p>Content is Loading please wait..</p>
            )}
          </div>
          {showLike && !liked && (
            <Box position="absolute" top="25px" right="19px">
              <div
                onClick={handleLike}
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  animation: liked ? 'bounce 1s' : 'none',
                }}
              >
                <FaThumbsUp
                  color={liked ? 'blue.200' : 'gray'}
                  size={'1.7em'}
                />
              </div>
              <NotificationBadge
                count={likeCount}
                effect={Effect.SCALE}
                ref={badgeRef}
                style={{
                  position: 'absolute',
                  top: 'unset',
                  right: '-15px',
                  bottom: '29px',
                }}
              />
            </Box>
          )}
        </Box>

        <Box flex="25%" height={'100%'} overflowY={'auto'} id="step2">
          <VideoList setShowLike={setShowLike} />
        </Box>
      </Flex>

      <Flex px={5} justifyContent={'center'}>
        <Tabs
          width={{
            base: '92%',
            sm: '92%',
            md: '70%',
            lg: '70%',
          }}
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList
            flexDirection={{
              base: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
            }}
            id="step3"
          >
            <Tab fontSize={'x-large'}>Course Overview</Tab>
            <Tab fontSize={'x-large'}>Projects</Tab>
            <Tab fontSize={'x-large'}>Review</Tab>
            <Tab fontSize={'x-large'}>Analyst</Tab>
            <Tab fontSize={'x-large'}>Leader Board</Tab>
          </TabList>

          <TabPanels>
            <TabPanel style={{ height: '90vh', padding: '0px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <Overview courseData={courseData} />
              </Suspense>
            </TabPanel>

            <TabPanel style={{ height: '90vh', padding: '0px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <UserProject />
              </Suspense>
            </TabPanel>

            <TabPanel style={{ height: '90vh', padding: '0px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <Review courseData={courseData} />
              </Suspense>
            </TabPanel>

            <TabPanel style={{ height: '90vh', padding: '0px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <UserAnalystTool name={userData?.name} />
              </Suspense>
            </TabPanel>

            <TabPanel style={{ height: '90vh', padding: '0px' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <LeaderBoard />
              </Suspense>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      {showPopup && (
        <LearningPageTour setShowPopup={setShowPopup} startTour={startTour} />
      )}
    </>
  );
};

export default VideoPage;
