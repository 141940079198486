import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const WishListContext = createContext();

export const WishListContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [wishListData, seWishListData] = useState([]);
  const userId = localStorage.getItem('UserId');

  useEffect(() => {
    if (wishListData) {
      console.log('wishListData', wishListData);
    }
  }, [wishListData]);

  const handelWishlist = async id => {
    console.log('add to wishList  api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    {
      let loadingToast;
      try {
        loadingToast = toast.loading('Adding  wishlist', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        await axios
          .put(
            `${backendUrl}/user/wishlist/add_item?courseId=${id}`,
            null,

            { headers: headers }
          )
          .then(res => {
            console.log('res.dataWishList', res.data);
            getWishList(userId);
            toast.success(res.data.message, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
          });
      } catch (err) {
        console.log(err);
        if (err.response.data.message)
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        else
          toast.error('Something went wrong', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }
  };

  const getWishList = async id => {
    console.log('adminGetAllWIshListId', id);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let apiUrl = `${backendUrl}/user/wishlist?`;
    if (id) {
      apiUrl += `userId=${id}`;
    }
    let loadingToast;
    try {
      loadingToast = toast.loading('fetching wishlist ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(apiUrl, { headers: headers })
        .then(res => {
          console.log(res.data.wishlist);
          seWishListData(res.data.wishlist);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        })
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  const handleWishListlDelete = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Deleting wishlist ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(`${backendUrl}/user/wishlist/remove_item?courseId=${id}`, null, {
          headers: headers,
        })
        .then(res => {
          console.log(res);
          getWishList();
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  const handelAllWishListDelete = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Deleting all wishlist ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(`${backendUrl}/user/wishlist/remove_all`, null, {
          headers: headers,
        })
        .then(res => {
          console.log(res);
          getWishList();
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <WishListContext.Provider
      value={{
        handelWishlist,
        getWishList,
        wishListData,
        handleWishListlDelete,
        handelAllWishListDelete,
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};

export default WishListContext;
