import axios from "axios";
import { createContext, useEffect, useState,useContext } from "react";
import toast from "react-hot-toast";
import EnvContext from "./EnvContext";

const SiteManagerContext = createContext();

export const SiteManagerContextProvider = ({ children }) => {
    const { backendUrl } = useContext(EnvContext);
    const [data,setData]=useState({});
const [update,setUpdate]=useState(false);
    


      const getSiteManager=async()=>{
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        let loadingToast;
        try {
          loadingToast = toast.loading('Fetching Site Manager', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          await axios
            .get(`${backendUrl}/site_manager`, { headers: headers })
            .then(res => {
              console.log('site_manager', res);
              setData(res.data.siteManager);
              toast.success(res.data.message, {
                position: 'top-right',
                style: {
                  marginTop: '62px',
                },
              });
            });
        } catch (err) {
          if (err.response.data.message)
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
          else
            toast.error('Something went wrong', {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
        } finally {
          // Close the loading state
          if (loadingToast) {
            toast.dismiss(loadingToast);
          }
        }
      };

      const handleAddSiteManager=async postData => {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        let loadingToast;
        console.log('addcopon api ', postData);
        try {
          loadingToast = toast.loading('Adding Site Manager', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          await axios
            .post(`${backendUrl}/site_manager`, postData, { headers: headers })
            .then(res => {
              // console.log('site_manager added', res);
              toast.success(res.data.message, {
                position: 'top-right',
                style: {
                  marginTop: '62px',
                },
              });
              getSiteManager();
            });
        } catch (err) {
          if (err.response.data.message)
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
          else
            toast.error('Something went wrong', {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
        } finally {
          // Close the loading state
          if (loadingToast) {
            toast.dismiss(loadingToast);
          }
        }
      };

      const updateSiteManager=async postData => {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        let loadingToast;
        console.log('addcopon api ', postData);
        try {
          loadingToast = toast.loading('Updating Site Manager', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          await axios
            .put(`${backendUrl}/site_manager?siteManagerId=${data._id}`, postData, { headers: headers })
            .then(res => {
              // console.log('site_manager added', res);
              getSiteManager();
              toast.success(res.data.message, {
                position: 'top-right',
                style: {
                  marginTop: '62px',
                },
              });
            });
        } catch (err) {
          if (err.response.data.message)
            toast.error(`${err.response.data.message}`, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
          else
            toast.error('Something went wrong', {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
        } finally {
          // Close the loading state
          if (loadingToast) {
            toast.dismiss(loadingToast);
          }
        }
      };
    
    return (
        <SiteManagerContext.Provider
            value={{ handleAddSiteManager ,getSiteManager,data ,updateSiteManager,setUpdate,update}}
        >
            {children}
        </SiteManagerContext.Provider>
    )
}

export default SiteManagerContext;

export const useSiteManager=(()=>useContext(SiteManagerContext));