import React, { useContext, useEffect, useState } from 'react';
import AssignmentContext from '../../../../context/AssignmentContext';
import { QuizCard } from './QuizCard';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { FaLongArrowAltLeft } from 'react-icons/fa';

export const Quizes = () => {
  const { questions, setSelectedAnswers, setQuesId, previousAttempts } =
    useContext(AssignmentContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextQuestion = () => {
    setCurrentIndex(prevIndex => prevIndex + 1);
  };

  const goToPreviousQuestion = () => {
    setCurrentIndex(prevIndex => prevIndex - 1);
  };
  useEffect(() => {
    if (Array.isArray(questions) && questions.length > 0) {
      setQuesId(questions[0]._id);
    }
  }, [questions]);

  return (
    <VStack
      minWidth={'65vh'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      flexDirection={{
        base: 'column',
        sm: 'column',
        lg: 'row'
      }}
    >
      {Array.isArray(questions) && questions.length > 0 && (
        <>
          <HStack
            gap={8}
            style={{ right: 0, top: '-5px' }}
            position={'absolute'}
          >
            <Button
              colorScheme="teal"
              variant="outline"
              size="sm"
              onClick={() => {
                if (currentIndex > 0) {
                  goToPreviousQuestion();
                  setSelectedAnswers([]);
                  setQuesId(questions[currentIndex - 1]._id);
                  const questionId = questions[currentIndex - 1]._id
                  previousAttempts(questionId)
                }
              }}
              disabled={currentIndex === 0}
              style={{ cursor: 'pointer' }}
            >
              <FaLongArrowAltLeft size={40} />
            </Button>
            <Button
              colorScheme="teal"
              variant="outline"
              size="sm"
              onClick={() => {
                if (currentIndex < questions.length - 1) {
                  goToNextQuestion();
                  setSelectedAnswers([]);
                  setQuesId(questions[currentIndex + 1]._id);
                  console.log("questions[currentIndex + 1]._id", (questions[currentIndex + 1]._id))
                  const questionId = questions[currentIndex + 1]._id
                  previousAttempts(questionId)
                }
              }}
              disabled={currentIndex === questions.length - 1}
              style={{ cursor: 'pointer' }}
            >
              <FaLongArrowAltLeft size={40} style={{ rotate: ' 180deg' }} />
            </Button>
          </HStack>
          <Box width={'100%'} height={'100%'}>
            <QuizCard quiz={questions[currentIndex]} index={currentIndex} />
          </Box>
        </>
      )}
    </VStack>
  );
};
