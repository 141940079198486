import { createContext, useContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const CouponContext = createContext();
let loadingToast;

export const CouponContextProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [couponData, setCouponData] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  const [spninnerStatus, setShowSpinnerStatus] = useState(false);





  const testing = () => {
    setTimeout(() => {
      setShowSpinnerStatus(!spninnerStatus);
    }, 5000);
  };
  // useEffect(() => {
  //   testing();

  // }, [showSpinner, spninnerStatus]);
  const { backendUrl } = useContext(EnvContext);
  const getCoupon = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Fetching coupon ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/coupon/all`, { headers: headers })
        .then(res => {
          console.log('getcouponres', res.data);
          setCoupons(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        })
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const addCoupon = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('addcopon api ', postData);
    try {
      loadingToast = toast.loading('Adding coupon ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .post(`${backendUrl}/coupon`, postData, { headers: headers })
        .then(res => {
          console.log('addcouponres', res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getCoupon();
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getAboutCoupon = async code => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/coupon/one?code=${code}`, { headers: headers })
        .then(res => {
          console.log('getAboutCoupon', res.data.data);
          setCouponData(res.data.data);
          setShowSpinnerStatus(true);
          setShowSpinner(false)
        }).catch(err => {
          setShowSpinnerStatus(false);
          setShowSpinner(false)
        })
    } catch (err) {
      console.log(err);
      setShowSpinnerStatus(false);
      setShowSpinner(false)
    }
  };

  const updateCoupon = async (postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Updating  coupon ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios.put(`${backendUrl}/coupon?discountId=${couponId}`, postData, { headers: headers })
        .then((res) => {
          console.log('updateCouponres', res)
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getCoupon();
        })
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }

  }

  const deleteCoupon = async (couponId) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Deleting coupon ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios.delete(`${backendUrl}/coupon?discountId=${couponId}`, { headers: headers })
        .then((res) => {
          console.log('updateCouponres', res)
          getCoupon();
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        })
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  }

  return (
    <CouponContext.Provider
      value={{
        addCoupon,
        getCoupon,
        coupons,
        setCouponId,
        couponId,
        getAboutCoupon,
        couponData,
        showSpinner,
        setShowSpinner,
        spninnerStatus,
        updateCoupon,
        deleteCoupon, setCouponData
      }}
    >
      {children}
    </CouponContext.Provider>
  );
};

export default CouponContext;
