import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Image,
  Text,
  Skeleton,
  ChakraProvider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Avatar,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { CourdeCard } from '../../../components/elements/CourdeCard';
import { jsCourse } from '../../../assets/images';
import toast from 'react-hot-toast';
import EnvContext from '../../../context/EnvContext';
import axios from 'axios';
import AuthContext from '../../../context/AuthContext';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import WishListContext from '../../../context/WishListContext';
import { useCourse } from '../../../context/CourseListContext';
import useSaveLastRoute from '../../../hooks/useSaveLastRoute';
import CartContext from '../../../context/CartContext';

export const Course = () => {
  useSaveLastRoute();
  const { adminAddCart } = useContext(CartContext);
  const { backendUrl } = useContext(EnvContext);
  const { products, getAllProducts } = useContext(AuthContext);
  const { getWishList } = useContext(WishListContext);
  const { setCourseData } = useCourse();
  const [filteredCourses, setFilteredCourses] = useState([]);
  const token = localStorage.getItem('token');
  const location = useLocation();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      getWishList();
    }
  }, [token]);

  useEffect(() => {
    console.log('products', products);
    if (products && products.length > 0) {
      setFilteredCourses(products);
    }
  }, [products]);

  const handleSearch = value => {
    console.log(value);
    const filtered = products.filter(course =>
      course.courseName.toLowerCase().includes(value.toLowerCase())
    );
    console.log(filtered);
    setFilteredCourses(filtered);
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 300),
    [products]
  );
  useEffect(() => {
    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [debouncedHandleSearch]);

  const handleInputChange = event => {
    debouncedHandleSearch(event.target.value);
  };

  useEffect(() => {
    getAllProducts();
    setCourseData({});
  }, []);

  const handelAdminAddtocart = item => {
    console.log('handelAdminAddtocart', item);
    const data = {
      id: item._id,
    };
    if (location.pathname === '/admin/home') adminAddCart(data);
  };

  return (
    <>
      <Box
        bg="gray.100"
        h="fit-content"
        minH={location.pathname === '/course' && '100vh'}
        px={{
          base: 2,
          md: 2,
          lg: 10,
        }}
      >
        <Flex
          direction="row"
          justify="space-between"
          m="1"
          p="2"
          align="center"
          bg="gray.100"
        >
          <Heading as="h4" size="lg">
            All Programs
          </Heading>
          <Stack spacing={4}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search Courses"
                bg="white"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Stack>
        </Flex>

        {location.pathname === '/admin/home' ? (
          <Flex
            alignContent={'center'}
            flexWrap={'wrap'}
            flexDirection={{
              base: 'column',
              md: 'row',
              lg: 'row',
            }}
            gap={'10px'}
            p={{
              base: 0,
              md: 2,
              lg: 10,
            }}
          >
            <Table variant="simple" size="md">
              <Thead bgColor={'blue.100'}>
                <Tr>
                  <Th>Logo</Th>
                  <Th>Course Name</Th>
                  <Th> Offer Price</Th>
                  <Th> Original Price</Th>
                  <Th>Add</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredCourses &&
                  filteredCourses.length > 0 &&
                  filteredCourses.map((item, i) => (
                    <Tr key={i}>
                      <Avatar
                        size="md"
                        name={item.courseName}
                        src={backendUrl + item.logo}
                      />{' '}
                      <Td>
                        <Text isTruncated maxWidth="150px">
                          {item.courseName}
                        </Text>
                      </Td>
                      <Td>{item.offerPrice}</Td>
                      <Td>{item.originalPrice}</Td>
                      <Td>
                        <Button
                          onClick={() => handelAdminAddtocart(item)}
                          colorScheme="teal"
                          size="sm"
                          leftIcon={<AddIcon />}
                        >
                          Add
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Flex>
        ) : (
          <Flex
            alignContent={'center'}
            flexWrap={'wrap'}
            flexDirection={{
              base: 'column',
              md: 'row',
              lg: 'row',
            }}
            gap={'10px'}
            p={{
              base: 0,
              md: 2,
              lg: 10,
            }}
          >
            {filteredCourses && filteredCourses.length > 0
              ? filteredCourses.map((item, i) => (
                  <CourdeCard
                    id={item._id}
                    logo={item.logo}
                    courseName={item.courseName}
                    img={item.banner}
                    courseLevel={item.courseLevel}
                    index={i}
                    offerPrice={item.offerPrice}
                    rating={item.totalRating}
                    no_users={item.noOfReviews}
                    originalPrice={item.originalPrice}
                  />
                ))
              : Array(5)
                  .fill('')
                  .map((_, i) => (
                    <Skeleton
                      key={i}
                      w={{
                        base: 300,
                        md: 350,
                        lg: 400,
                      }}
                      h={{
                        base: '60vh',
                        md: '40vh',
                        lg: '60vh',
                      }}
                      m="4"
                    />
                  ))}
            {}
          </Flex>
        )}
      </Box>
    </>
  );
};
