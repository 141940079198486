import React, { useState, useEffect, useContext } from 'react';
import { Box, Spinner, Text, VStack } from '@chakra-ui/react';
import {
  decryptData,
  encryptData,
  generateTransactionID,
} from '../../Utils/function';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const LoadingPage = () => {
  const navigate = useNavigate();
  const {merchantID} =useParams();
  console.log("merchantID",merchantID)
  // const [merchantID, setMerchantID] = useState(null);
  const secretKey = process.env.REACT_APP_CRYPTO_KEY;
  const { backendUrl } = useContext(EnvContext);
  // const encryptedMerchantID = localStorage.getItem('merchantID');
  const location = useLocation();
  useEffect(() => {
    const dataReceived = location.state;

    console.log("dataReceived", dataReceived);
  }, [location])

  // useEffect(() => {
  //   if (encryptedMerchantID) {
  //     // Decrypt the merchant ID
  //     console.log('encryptedMerchantID', encryptedMerchantID);
  //     const decryptedMerchantID = decryptData(encryptedMerchantID, secretKey);
  //     console.log('decryptedMerchantID', decryptedMerchantID);
  //     setMerchantID(decryptedMerchantID);
  //   }
  // }, [encryptedMerchantID]);

  const fetchTransactionDetails = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('fetchTransactionDetails');
    try {
      console.log('fetchTransactionDetails gdiydgasuyfogbauiysdt');
      await axios
        .get(
          `${backendUrl}/user/transaction/details?merchantTransactionId=${merchantID}`,
          { headers: headers }
        )
        .then(res => {
          console.log('all transcaction Details', res.data);
          const data = res.data.data;
          if (data?.payment_status) {
            if (data?.payment_status === 'PAYMENT_SUCCESS')
              navigate('/successful_payment_page',{state:{...data}});
            else navigate('/failed_payment_page');
          } else navigate('/failed_payment_page');
        });
    } catch (err) {
      console.log(err);
      navigate('/failed_payment_page');
    }
  };

  const verifyTransaction = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('verifyTransaction');
    try {
      console.log('verifyTransaction shdhdhdhdhd');
      await axios
        .get(`${backendUrl}/user/transaction/status/${merchantID}`, {
          headers: headers,
        })
        .then(res => {
          console.log('verifyTransaction', res.data);
          fetchTransactionDetails();
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!merchantID) return;
    console.log('merchantID', merchantID);
    verifyTransaction();
  }, [merchantID]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundColor="white"
    >
      <VStack spacing={4}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text fontSize="xl" color="gray.700">
          Processing your transaction...
        </Text>
        <Text fontSize="md" color="gray.500">
          Please do not refresh or close this page
        </Text>
      </VStack>
    </Box>
  );
};

export default LoadingPage;
