import { Flex } from '@chakra-ui/react';
import React from 'react';
import UserDashboardSidebar from '../../../components/layouts/UserDashboardSidebar';
import TransactionDetails from '../../admin/transactions/TransactionDetails';

const AllTransactions = () => {
  return (
    <>
      <Flex
        className="userHome"
        flexDirection={'row'}
        alignItems={'flex-start'}
        margin={'auto'}
        marginTop={10}
        justifyContent={'center'}
        width={'100%'}
      >
        <UserDashboardSidebar />
        <TransactionDetails />
      </Flex>
    </>
  );
};

export default AllTransactions;
