import { createContext, useContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const MentorContext = createContext();

export const MentorContextProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const { backendUrl } = useContext(EnvContext);
  const [mentors, setMentors] = useState([]);
  const [mentorName, setMentorName] = useState('');
  const [mentorId, setMentorId] = useState(null);
  const [mentorData, setMentorData] = useState({})


  const fetchMentor = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Loading Mentors', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/course/mentor/search?mentorName=${mentorName}`, {
          headers: headers
        })
        .then(res => {
          console.log('res.data.data', res.data.data);
          setMentors(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        })
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const addMentor = async (postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    // console.log('postDAta', postData);
    // console.log('mentorId', mentor);
    try {
      loadingToast = toast.loading('Mentor List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .post(`${backendUrl}/course/mentor`, postData, { headers: headers })
        .then(res => {
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          fetchMentor();
        });
    } catch (err) {
      console.log("mentor error", err)
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getMentor = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log("get mentor api hit")
    let loadingToast;
    try {
      loadingToast = toast.loading('Mentor List is loading', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios.get(`${backendUrl}/course/mentor?mentorId=${mentorId}`, { headers: headers })
        .then(res => {
          console.log("mentor Data", res.data.data)
          setMentorData(res.data.data)
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }

  }

  const updateMentor = async (postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    // console.log('postDAta', postData);
    // console.log('mentorId', mentor);
    try {
      loadingToast = toast.loading('Mentor is updateing', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(`${backendUrl}/course/mentor?mentorId=${mentorId}`, postData, { headers: headers })
        .then(res => {
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          fetchMentor();
        });
    } catch (err) {
      console.log("updateMentor", err)
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <MentorContext.Provider
      value={{
        fetchMentor,
        setToken,
        mentors,
        setMentorName,
        mentorName,
        setMentorId,
        mentorId,
        addMentor,
        updateMentor,
        getMentor,
        mentorData,
      }}
    >
      {children}
    </MentorContext.Provider>
  );
};

export default MentorContext;
