// starting date : 26 Feb 2024

import React, { useContext, useEffect } from 'react';
import { AllRoutes } from './routes/AllRoutes';
import toast, { Toaster } from 'react-hot-toast';
import CartContext from './context/CartContext';

function App() {
  localStorage.removeItem('chakra-ui-color-mode');
  console.log(process.env)
  return (
    <>
      <AllRoutes />
      <Toaster containerStyle={{ marginTop: "5rem" }} />
    </>
  )
}
// new updated file new commit
export default App;
