import CryptoJS from 'crypto-js';

// Function to encrypt data
export const encryptData = (data, secretKey) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// Function to decrypt data
export const decryptData = (encryptedData, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  console.log('decrypt function data', encryptedData);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export function generateTransactionID() {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1000000);
  const merchantPrefix = 'T';
  const transactionID = `${merchantPrefix}${timestamp}${randomNum}`;
  return transactionID;
}
export const formatDate = dateString => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
};

export const formatTime = dateString => {
  const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const date = new Date(dateString);
  return date.toLocaleTimeString(undefined, options);
};

export const sortTransactionsByDate = transactions => {
  return transactions.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
};

export function getUniqueUserProjects(submittedProjects) {
  if (!submittedProjects) return [];
  const filteredProjects = submittedProjects.filter(project =>
    project.hasOwnProperty('completionTime')
  );
  // console.log('filteredProjects', filteredProjects);
  // Sort the filtered projects by 'createdAt'
  const sorted = [...filteredProjects].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  const uniqueProjects = [];
  const projectNames = new Set();
  if (localStorage.getItem('history') === '/admin/home') {
    for (let project of sorted) {
      if (!projectNames.has(project.project.name)) {
        projectNames.add(project.project.name);
        uniqueProjects.push(project);
      }
    }

    console.log('uniqueProjects', uniqueProjects);
    return uniqueProjects;
  } else if (
    localStorage.getItem('history') === '/admin/courses' ||
    '/admin/manage_projects'
  ) {
    console.log('sorted', sorted);
    // Use a Map to collect unique project names for each user
    const userProjectsMap = new Map();

    for (let project of sorted) {
      const userId = project.user;
      const projectName = project.project.name;

      if (!userProjectsMap.has(userId)) {
        userProjectsMap.set(userId, new Set());
      }

      const userProjects = userProjectsMap.get(userId);

      if (!userProjects.has(projectName)) {
        userProjects.add(projectName);
      }
    }

    // Create an array of unique user-project objects
    const uniqueUserProjects = [];

    for (let project of sorted) {
      const userId = project.user;
      const projectName = project.project.name;

      if (userProjectsMap.get(userId).has(projectName)) {
        uniqueUserProjects.push(project);
        userProjectsMap.get(userId).delete(projectName); // Remove the project name to ensure uniqueness
      }
    }

    console.log('uniqueUserProjects', uniqueUserProjects);
    return uniqueUserProjects;
  }
  //   else if (localStorage.getItem('history') === '/admin/manage_projects') {
  //     console.log('sorted', sorted);
  //     const userProjectsMap = new Map();

  //     for (let project of sorted) {
  //       const userId = project.user;
  //       const projectName = project.project.name;

  //       if (!userProjectsMap.has(userId)) {
  //         userProjectsMap.set(userId, new Set());
  //       }

  //       const userProjects = userProjectsMap.get(userId);

  //       if (!userProjects.has(projectName)) {
  //         userProjects.add(projectName);
  //       }
  //     }

  //     // Create an array of unique user-project objects
  //     const uniqueUserProjects = [];

  //     for (let project of sorted) {
  //       const userId = project.user;
  //       const projectName = project.project.name;

  //       if (userProjectsMap.get(userId).has(projectName)) {
  //         uniqueUserProjects.push(project);
  //         userProjectsMap.get(userId).delete(projectName); // Remove the project name to ensure uniqueness
  //       }
  //     }

  //     console.log('uniqueUserProjects', uniqueUserProjects);
  //    return uniqueUserProjects;
  //   }
}
