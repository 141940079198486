import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import AuthContext from './AuthContext';

const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const { userData, handleUserData } = useContext(AuthContext);
  const [cartItems, setCartItems] = useState([]);
  const [unAuthCartItems, setUnAuthCartItems] = useState([]);
  const [totalCart, setTotalCart] = useState(0);
  const [userId, setUserID] = useState(null);
  const [adminCart, setAdminCart] = useState([]);
  const token = localStorage.getItem('token');

  // for  subscription
  const [subscriptions, setSubscriptions] = useState([]);
  const [aboutSubscription, setAboutScription] = useState({});

  useEffect(() => {
    if (Array.isArray(cartItems)) {
      const sum = cartItems.reduce((total, item) => total + item.offerPrice, 0);
      console.log('sum', sum);
      setTotalCart(sum);
    } else {
      if (Array.isArray(unAuthCartItems)) {
        const sum = unAuthCartItems.reduce(
          (total, item) => total + item.offerPrice,
          0
        );
        console.log('sum', sum);
        setTotalCart(sum);
      }
    }
  }, [cartItems, unAuthCartItems]);

  useEffect(() => {
    console.log('set items hit');
    if (!token) setCartItems('');
  }, [token]);

  useEffect(() => {
    if (!token) {
      console.log('Storing unAuthCartItems:', unAuthCartItems); // Log unAuthCartItems
      localStorage.setItem('unAuthCartItems', JSON.stringify(unAuthCartItems));
    }
  }, [unAuthCartItems, token]);

  const handelAddCart = async data => {
    console.log('add to cart  api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;
    // console.log('postDAta', postData);
    // console.log('mentorId', mentor);
    if (token) {
      try {
        loadingToast = toast.loading('Adding course to cart', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        await axios
          .put(
            `${backendUrl}/user/cart/add_item?courseId=${data.id}`,
            null,

            { headers: headers }
          )
          .then(res => {
            console.log(res.data);
            getcart();
            toast.success(res.data.message, {
              position: 'top-right',
            });
          });
      } catch (err) {
        console.log(err);
        if (err.response.data.message)
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
          });
        else
          toast.error('Something went wrong', {
            position: 'top-right',
          });
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    } else {
      const existingItem = Array.isArray(unAuthCartItems)
        ? unAuthCartItems.find(item => item.id === data.id)
        : undefined;

      if (existingItem) {
        // If the item already exists, display a warning message
        alert('Course is already added to the cart!');
      } else {
        // If the item does not exist, add it to unAuthCartItems
        setUnAuthCartItems([...unAuthCartItems, data]);
      }
    }
  };

  const handelAddGroupCart = async courseIds => {
    console.log('add to group cart  api hit', courseIds);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;
    // console.log('postDAta', postData);
    // console.log('mentorId', mentor);
    if (token) {
      try {
        loadingToast = toast.loading('Adding course to cart', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        await axios
          .put(
            `${backendUrl}/user/cart/add_group_items`,
            { courseIds: courseIds },

            { headers: headers }
          )
          .then(res => {
            console.log(res.data);
            toast.success(res.data.message, {
              position: 'top-right',
            });
            setUnAuthCartItems('');
            localStorage.removeItem('unAuthCartItems');
            getcart();
          });
      } catch (err) {
        console.log(err);
        if (err.response.data.message)
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
          });
        else
          toast.error('Something went wrong', {
            position: 'top-right',
          });
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }
  };

  const getcart = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (token) {
      try {
        await axios
          .get(`${backendUrl}/user/cart/`, { headers: headers })
          .then(res => {
            console.log('userData', userData);
            console.log('getcartres', res.data.cart);
            setCartItems(res.data.cart);
            setUnAuthCartItems('');
            localStorage.removeItem('unAuthCartItems');
          })
          .catch(err => {
            console.log(err);
          });
      } catch (err) {}
    }
  };

  const handeCartlDelete = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    if (token) {
      try {
        loadingToast = toast.loading(' Deleting item  ...', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        await axios
          .put(`${backendUrl}/user/cart/remove_item?courseId=${id}`, null, {
            headers: headers,
          })
          .then(res => {
            console.log(res);
            getcart();
          });
      } catch (err) {
        console.log(err);
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    } else {
      console.log('delete button hit', id);
      const updatedCartItems = unAuthCartItems.filter(item => item.id !== id);
      setUnAuthCartItems(updatedCartItems);
    }
  };

  const handelAllDelete = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    if (token) {
      try {
        loadingToast = toast.loading(' Deleting all item  ...', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        await axios
          .put(`${backendUrl}/user/cart/remove_all`, null, { headers: headers })
          .then(res => {
            console.log(res);
            getcart();
          });
      } catch (err) {
        console.log(err);
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }
  };

  const adminDeleteCart = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading(' Deleting item  ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(
          `${backendUrl}/user/cart/remove_item?userId=${userId}&courseId=${id}`,
          null,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getAdminCart();
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getAdminCart = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    if (token) {
      try {
        await axios
          .get(`${backendUrl}/user/cart?userId=${userId}`, { headers: headers })
          .then(res => {
            console.log('getcartres', res.data.cart);
            setAdminCart(res.data.cart);
          })
          .catch(err => {
            console.log(err);
          });
      } catch (err) {}
    }
  };

  const adminAddCart = async data => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('adminAddCart', data.id, userId);
    let loadingToast;
    try {
      loadingToast = toast.loading('Adding course to cart', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(
          `${backendUrl}/user/cart/add_item?userId=${userId}&courseId=${data.id}`,
          null,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('adminAddCartres', res);
          getAdminCart();
          toast.success(res.data.message, {
            position: 'top-right',
          });
        });
    } catch (err) {
      console.log(err);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getAllSubscription = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/subscription/all`, { headers: headers })
        .then(({ data }) => {
          console.log('getAllSubscriptionres', data.data);
          setSubscriptions(data.data);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAboutSubscription = async subscriptionId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/subscription?subscriptionId=${subscriptionId}`, {
          headers: headers,
        })
        .then(({ data }) => {
          console.log('getAboutSubscriptionres', data.data);
          setAboutScription(data.data);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSubscriptionPlan = async subscriptionId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('subscriptionId', subscriptionId);
    try {
      await axios
        .delete(`${backendUrl}/subscription?subscriptionId=${subscriptionId}`, {
          headers: headers,
        })
        .then(({ data }) => {
          console.log('DeleteSubscriptionPlanres', data.data);
          getAllSubscription();
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const addCourseSubscription = async (subscriptionId, courseId) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      courseId: courseId,
    };
    try {
      await axios
        .put(
          `${backendUrl}/subscription/add_course?subscriptionId=${subscriptionId}`,
          postData,
          { headers: headers }
        )
        .then(({ data }) => {
          console.log('addCourseSubscriptionres', data.data);
          getAllSubscription();
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const removeCourseSubscription = async (subscriptionId, courseId) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = {
      courseId: courseId,
    };
    try {
      await axios
        .put(
          `${backendUrl}/subscription/remove_course?subscriptionId=${subscriptionId}`,
          postData,
          { headers: headers }
        )
        .then(({ data }) => {
          console.log('removeCourseSubscriptionres', data.data);
          getAllSubscription();
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const ManuallyAddPlan = async (subscriptionPlanId, userId, planDuration) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log(
      'subscriptionPlanId',
      subscriptionPlanId,
      'userId',
      userId,
      'planDuration',
      planDuration
    );
    try {
      await axios
        .post(
          `${backendUrl}/user/subscription?subscriptionPlanId=${subscriptionPlanId}&userId=${userId}&planDuration=${planDuration}`,
          null,
          { headers: headers }
        )
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CartContext.Provider
      value={{
        setCartItems,
        handelAddCart,
        getcart,
        setUnAuthCartItems,
        cartItems,
        handeCartlDelete,
        handelAllDelete,
        unAuthCartItems,
        handelAddGroupCart,
        setUserID,
        userId,
        adminDeleteCart,
        adminCart,
        getAdminCart,
        adminAddCart,
        totalCart,
        setSubscriptions,
        subscriptions,
        aboutSubscription,
        setAboutScription,
        getAllSubscription,
        getAboutSubscription,
        deleteSubscriptionPlan,
        addCourseSubscription,
        removeCourseSubscription,
        ManuallyAddPlan,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
