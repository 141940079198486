import React, { useContext, useEffect, useState, useRef } from 'react';
import CourseContext from '../../context/CourseListContext';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Button,
  Stack,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  Box,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Textarea,
  ChakraProvider,
  Container,
  Image,
  Text,
  Flex,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { Formik, Form, Field, FieldArray } from 'formik';
import EnvContext from '../../context/EnvContext';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { GrAdd } from 'react-icons/gr';
import ProjectContext from '../../context/ProjectContext';

function UpdateProject() {
  const [notesFile, setNotesFile] = useState({});
  const [optionImages, setOptionImages] = useState([]);
  const { courseId } = useContext(CourseContext);

  const token = localStorage.getItem('token');
  const { backendUrl } = useContext(EnvContext);
  const { fetchAllProjects, ProjectData, projectId, getProject } = useContext(ProjectContext);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState({});
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
  };
  const checkboxValues = ['GitHub', 'Drive', 'YouTube'];
  const [checkedValues, setCheckedValues] = useState([]);
  const [uncheckedValues, setUncheckedValues] = useState([]);
  const [items, setItems] = useState([]);
  const [inputValue, setInputValue] = useState('');

  // const checkedValues = checkboxValues.filter(value => ProjectData.submissionLinks.includes(value));
  useEffect(() => {
    if (ProjectData.name) setCheckedValues(ProjectData.submissionLinks);
  }, [ProjectData]);

  useEffect(() => {
    if (checkedValues) console.log('checkedValues', checkedValues);
  }, [checkedValues]);

  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    guidelines: '',
    task: '',
    submissionLinks: [],
  });
  const [newItem, setNewItem] = useState('');

  const addItem = () => {
    if (newItem.trim() !== '') {
      setCheckedValues([...checkedValues, newItem]);
      setNewItem('');
    }
  };

  const removeItem = index => {
    const updatedItems = checkedValues.filter((_, i) => i !== index);
    setCheckedValues(updatedItems);
  };
  const handleFileChange = event => {
    const files = event.target.files;
    setOptionImages([...files]);
  };



  useEffect(() => {
    if (ProjectData) {
      setInitialValues({
        ...initialValues,
        name: ProjectData.name,
        task: ProjectData.task,
        description: ProjectData.description,
        submissionLinks: [],
      });
    }
  }, [ProjectData]);

  const handleSubmit = async values => {
    console.log(values);
    console.log('notesFile', notesFile);
    console.log('addcourseId', courseId);
    console.log('fruits', optionImages);

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('task', values.task);
    formData.append('guidelines', notesFile);

    optionImages.forEach(image => formData.append('images', image));

    checkedValues.forEach((value, index) => {
      formData.append(`submissionLinks[${index}]`, value);
    });
    for (let entry of formData.entries()) {
      const key = entry[0];
      const value = entry[1];
      console.log(key, value);
    }
    let loadingToast;

    try {
      loadingToast = toast.loading('Updating project ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(`${backendUrl}/course/project?projectId=${projectId}`, formData, {
          headers: headers,

        })
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          fetchAllProjects();
          getProject(projectId);
        });
    } catch (err) {
      console.log(err)
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }) => (
          <Form>
            <Field name="name">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel>Project Name :</FormLabel>
                  <Input {...field} placeholder=" Project name" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel>Description :</FormLabel>
                  <Textarea {...field} placeholder="Description" />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="task">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.task && form.touched.task}>
                  <FormLabel>task :</FormLabel>
                  <Textarea {...field} placeholder="task" />
                  <FormErrorMessage>{form.errors.task}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="guidelines">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.guidelines && form.touched.guidelines}
                >
                  <FormLabel>guidelines (PDF) :</FormLabel>
                  {/* Your file upload logic here */}
                  <input
                    type="file"
                    onChange={e => setNotesFile(e.target.files[0])}
                  />
                  <FormErrorMessage>{form.errors.guidelines}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <FormLabel>Links :</FormLabel>
            <VStack align="flex-start" spacing={4}>
              <Flex flexDirection={'row'} flexWrap={'wrap'} gap={5} >
                {checkedValues.map((item, index) => (
                  <Flex key={index} bg={'gray.100'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} px={2} borderRadius={5}>
                    <Text>{item}</Text>
                    <IconButton
                      icon={<CloseIcon />}
                      variant="ghost"
                      colorScheme="red"
                      aria-label="Delete Item"
                      onClick={() => removeItem(index)}
                    />
                  </Flex>
                ))}
              </Flex>
              <HStack>
                <Input
                  placeholder="Add new item"
                  value={newItem}
                  onChange={e => setNewItem(e.target.value)}
                />
                <IconButton
                  icon={<AddIcon />}
                  colorScheme="teal"
                  aria-label="Add Item"
                  onClick={addItem}
                />
              </HStack>
            </VStack>
            <FormLabel>Supportive Images : </FormLabel>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*"
            />

            <Stack spacing="6" mt={4}>
              <Button type="submit">Submit</Button>
            </Stack>
          </Form>
        )}
      </Formik>

      <Toaster />
    </Box>
  );
}

export default UpdateProject;
