import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  VStack,
  Heading,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Checkbox,
  HStack,
  Box,
  Text,
  Stack,
} from '@chakra-ui/react';
import EnvContext from '../../../../context/EnvContext';
import AssignmentContext from '../../../../context/AssignmentContext';
import ContentContext from '../../../../context/ContentContext';

export const QuizCard = ({ quiz, index }) => {
  const { backendUrl } = useContext(EnvContext);
  const [backGround, setBackGround] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const {
    selectedAnswers,
    setSelectedAnswers,
    submitAnswer,
    queResult,
    setQueResult,
    quesId,
    previousAttempts,
  } = useContext(AssignmentContext);

  const { assign } = useContext(ContentContext);
  useEffect(() => {
    // Check if quesId is within an array
    if (Array.isArray(assign)) {
      const isQuesIdAnswered = assign.some(
        answer => answer.question._id === quesId
      );

      setIsAnswered(isQuesIdAnswered);
      if (isQuesIdAnswered) {
        const answers = assign
          .filter(answer => answer.question._id === quesId)
          .map(item => item.answer);
        setSelectedAnswers(answers.flat());
      } else {
        setSelectedAnswers([]);
      }
    }
  }, [quesId]);
  useEffect(() => {
    if (selectedAnswers) {
      console.log('selectedAnswers', selectedAnswers);
      console.log('quiz', quiz);
    }
  }, [selectedAnswers]);
  useEffect(() => {
    if (queResult) setBackGround();
  }, [queResult]);

  useEffect(() => {
    if (backGround) console.log('backGround', backGround);
  }, [backGround]);
  function trimAndReturnFileName(filePath) {
    // Find the index of the first hyphen
    const hyphenIndex = filePath.indexOf('-');

    // Extract the substring starting from the character after the hyphen
    const fileName = filePath.substring(hyphenIndex + 1);

    return fileName;
  }
  const handleCheckboxChange = value => {
    if (selectedAnswers.includes(value)) {
      setSelectedAnswers(selectedAnswers.filter(el => el !== value));
    } else {
      setSelectedAnswers([...selectedAnswers, value]);
    }
  };

  const handleRadioChange = value => {
    setSelectedAnswers([value]);
  };
  const handleSubmitAnswer = () => {
    console.log('selectedAnswers', selectedAnswers);
    const formData = new FormData();
    formData.append('answer', selectedAnswers);
    const postData = {
      answer: selectedAnswers,
    };
    submitAnswer(selectedAnswers);
  };
  return (
    <HStack spacing={4} alignItems="stretch" width={'100%'} flexDirection={{
      base: 'column',
      sm: 'column',
      lg: 'row'
    }}>
      <VStack w={{
        base: '65%',
        md: '100%',
        lg: "40%"
      }} >
        <Heading as="h1" size="lg">
          Question {index + 1}: {quiz.question.text}
        </Heading>
        {
          quiz?.question?.image ? <img
            src={backendUrl + quiz.question.image}
            style={{ width: '366px', height: '244px' }}
          ></img> : " "
        }

      </VStack>

      <VStack w={{
        base: '75%',
        md: '100%',
        lg: "60%"
      }} bg={"gray.100"} height={"100%"} p={5}>
        <FormControl as="fieldset" maxH={'55vh'} overflowY={'scroll'}>
          <FormLabel as="legend">
            Choose {quiz.questionType === 'multiple' ? 'Multiple' : 'One'}:
          </FormLabel>
          <RadioGroup width={'100%'}>
            {quiz.questionType === 'multiple' ? (
              <>
                {quesId === queResult?.answer?.question ? (
                  queResult?.answer?.isCorrectAnswer ? (
                    ''
                  ) : (
                    <Text fontWeight={700}>
                      No of Attempts left:{' '}
                      {quiz.noOfAttempts - queResult?.noOfAttempts}
                    </Text>
                  )
                ) : null}
                <VStack
                  spacing={2}
                  pl={10}
                  alignItems={'flex-start'}
                  bg={
                    quesId === queResult?.answer?.question
                      ? queResult?.answer?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  {Array.isArray(quiz.options.text) &&
                    quiz.options.text.map((el, i) => (
                      <Checkbox
                        borderColor="blue.500"
                        key={el}
                        value={el}
                        isChecked={selectedAnswers.includes(el)}
                        onChange={() => handleCheckboxChange(el)}
                      // bg={!queResult.isCorrectAnswer || (queResult.isCorrectAnswer && selectedAnswers.includes(el)) ? 'black' : 'red'}
                      >
                        {el}
                      </Checkbox>
                    ))}
                </VStack>
                <HStack
                  spacing={2}
                  pl={10}
                  flexWrap={'wrap'}
                  bg={
                    quesId === queResult?.answer?.question
                      ? queResult?.answer?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  {Array.isArray(quiz.options.image) &&
                    quiz.options.image.map((el, i) => (
                      <Checkbox
                        mt={2}
                        borderColor="blue.500"
                        key={el}
                        value={el}
                        isChecked={selectedAnswers.includes(
                          trimAndReturnFileName(el)
                        )}
                        onChange={() =>
                          handleCheckboxChange(trimAndReturnFileName(el))
                        }
                      >
                        <img
                          src={backendUrl + el}
                          style={{ width: '275px', height: '184px' }}
                        ></img>
                      </Checkbox>
                    ))}
                </HStack>
              </>
            ) : (
              <>
                {quesId === queResult?.answer?.question ? (
                  queResult?.answer?.isCorrectAnswer ? (
                    ''
                  ) : (
                    <Text fontWeight={700}>
                      No of Attempts left:{' '}
                      {quiz.noOfAttempts - queResult?.noOfAttempts}
                    </Text>
                  )
                ) : null}
                <HStack
                  spacing={2}
                  pl={10}
                  flexWrap={'wrap'}
                  bg={
                    quesId === queResult?.answer?.question
                      ? queResult?.answer?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  {Array.isArray(quiz.options.image) &&
                    quiz.options.image.map((el, i) => (
                      <Radio
                        mt={2}
                        key={el}
                        value={el}
                        isChecked={selectedAnswers.includes(
                          trimAndReturnFileName(el)
                        )}
                        onChange={() =>
                          handleRadioChange(trimAndReturnFileName(el))
                        }
                        borderColor="blue.500"
                      >
                        <img
                          src={backendUrl + el}
                          style={{ width: '275px', height: '184px' }}
                        ></img>
                      </Radio>
                    ))}
                </HStack>
                <VStack
                  spacing={2}
                  pl={10}
                  flexWrap={'wrap'}
                  alignContent={'flex-start'}
                  alignItems={'flex-start'}
                  bg={
                    quesId === queResult?.answer?.question
                      ? queResult?.answer?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  <RadioGroup
                    onChange={handleRadioChange}
                    value={selectedAnswers[0]}
                  >
                    <Stack direction="column">
                      {' '}
                      {/* Adjust direction as needed */}
                      {Array.isArray(quiz.options.text) &&
                        quiz.options.text.map((el, i) => (
                          <Radio key={i} borderColor="blue.500" value={el}>
                            {el}
                          </Radio>
                        ))}
                    </Stack>
                  </RadioGroup>
                </VStack>
              </>
            )}
          </RadioGroup>
        </FormControl>
        <HStack justifyContent={'center'}>
          {quesId === queResult?.answer?.question ? (
            queResult?.answer?.isCorrectAnswer ? (
              isAnswered ? (
                // If quesId is already answered, show the text
                <p>Already answered and correct!</p>
              ) : (
                // If quesId is not answered, show the button
                <Button
                  onClick={handleSubmitAnswer}
                  colorScheme="blue"
                  style={{ width: '20rem' }}
                >
                  Submit Answer
                </Button>
              )
            ) : (
              <Button
                onClick={() => {
                  setSelectedAnswers([]);
                  setQueResult({});
                }}
                colorScheme="red"
                width={'20rem'}
              >
                Retry
              </Button>
            )
          ) : isAnswered ? (
            // If quesId is already answered, show the text
            <p>Already answered the question correctly!</p>
          ) : (
            // If quesId is not answered, show the button
            <Button
              onClick={handleSubmitAnswer}
              colorScheme="blue"
              style={{ width: '20rem' }}
            >
              Submit Answer
            </Button>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
