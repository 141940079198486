import axios from 'axios';
import { createContext, useEffect, useState, useContext } from 'react';
import toast from 'react-hot-toast';
import EnvContext from './EnvContext';
import ContentContext from './ContentContext';
import CourseContext from './CourseListContext';

const AssignmentContext = createContext();

export const AssignmentContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const { contentId, fetchUserScoreboard, fetchLeaderBoard } =
    useContext(ContentContext);
  const [assignments, setAssignments] = useState([]);
  const { courseId, scorecard } = useContext(CourseContext);
  const [prevAnsw, setPrevAnsw] = useState([]);
  const [qa, setQa] = useState({});
  const [answers, setAnswers] = useState(null);
  const [quesId, setQuesId] = useState(null);
  const [token, setToken] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const userId = localStorage.getItem('userId');
  const [queResult, setQueResult] = useState({});
  const [prevAttempts, setPrevAttempts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    if (quesId) {
      console.log('quesId', quesId);
      console.log('adsuoad', queResult?.answer?.question);
      previousAttempts(quesId);
    }
  }, [quesId]);

  // function filterByQuestionId(dataArray, questionIds) {
  //   return dataArray.filter(obj => questionIds.includes(obj.question._id));
  // }

  // useEffect(() => {
  //   if (scorecard && Array.isArray(scorecard.assignment)) {
  //     console.log("previous assignemnt",scorecard.assignment)
  //     const filteredData = filterByQuestionId(scorecard.assignment, quesId);
  //     console.log('filteredData of previous answers', filteredData);
  //   }
  // }, [scorecard, quesId]);
  const fetchAllAssignments = async value => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Assignments are loading ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/course/assignment?courseContentId=${value}`, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data.questions);
          setAssignments(res.data.questions);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      console.log(err.response.data.message);
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getQnA = async value => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Q & A is loading ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .get(`${backendUrl}/course/assignment/question?questionId=${value}`, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data);
          setQa(res.data.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const addQnA = async value => {
    console.log('add qn a  api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    {
      let loadingToast;
      // console.log('postDAta', postData);
      // console.log('mentorId', mentor);
      try {
        loadingToast = toast.loading('Adding New Question', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        await axios
          .post(
            `${backendUrl}/course/assignment/question?courseContentId=${contentId}`,
            value,
            { headers: headers }
          )
          .then(res => {
            console.log(res.data);
            toast.success(res.data.message, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
            fetchAllAssignments(contentId);
            setAnswers(null);
            setSelectedItems(null);
          });
      } catch (err) {
        console.log(err);
        if (err.response.data.message)
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        else
          toast.error('Something went wrong', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
    }
  };

  const getAllQuestions = async courseContentId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(
          `${backendUrl}/course/assignment?courseContentId=${courseContentId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('getAllQuestions fetched', res.data);
          setQuestions(res.data.questions);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const submitAnswer = async answer => {
    console.log('add qn a  api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;

    try {
      loadingToast = toast.loading('Checking answer', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      const response = await axios.post(
        `${backendUrl}/user/rs/assignment_submission?questionId=${quesId}`,
        { answer }, // Pass the answer array directly here
        { headers }
      );

      const data = response.data.data;
      console.log(data);
      setQueResult(data);
      toast.success(response.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      fetchUserScoreboard(userId, courseId);
      fetchLeaderBoard();
      previousAttempts(quesId);
    } catch (error) {
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      }
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const previousAttempts = async questionId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(
          `${backendUrl}/user/rs/assignment_submission/pre_attempts?questionId=${questionId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('previousAttempts fetched', res.data.data.prevAttempts);
          setPrevAttempts(res.data.data.prevAttempts);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const resetAssignmentAnswers = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .delete(
          `${backendUrl}/user/rs/assignment_submission?courseId=${courseId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('res delete', res);
          fetchUserScoreboard(userId, courseId);
          fetchLeaderBoard();
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AssignmentContext.Provider
      value={{
        fetchAllAssignments,
        addQnA,
        setToken,
        assignments,
        setQuesId,
        quesId,
        getQnA,
        qa,
        setAnswers,
        answers,
        getAllQuestions,
        questions,
        submitAnswer,
        setSelectedAnswers,
        selectedAnswers,
        prevAnsw,
        queResult,
        setQueResult,
        prevAttempts,
        resetAssignmentAnswers,
        previousAttempts,
        setSelectedItems,
        selectedItems,
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

export default AssignmentContext;
