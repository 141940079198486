import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  useStyleConfig,
  Button,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import '../../allcss/coursecard.css';
import { StarRating } from './StarRating';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import { CustomNavLink } from './CustomNavLink ';
import {
  NavLink,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { MdAddShoppingCart } from 'react-icons/md';
import axios from 'axios';
import CourseContext from '../../context/CourseListContext';
import EnvContext from '../../context/EnvContext';
import { a } from 'react-spring';
import { FaRegHeart } from 'react-icons/fa';
import CartContext from '../../context/CartContext';
import WishListContext from '../../context/WishListContext';
import { CustomCard } from '../../pages/user/authentication/video/VideoList';
import AuthContext from '../../context/AuthContext';
// import { Button } from 'react-scroll';
import { FaHeart } from "react-icons/fa";

export const CourdeCard = ({
  courseName,
  img,
  courseLevel,
  originalPrice,
  offerPrice,
  rating,
  no_users,
  index,
  id,
  logo,
}) => {
  const { handelAddCart, adminAddCart, unAuthCartItems, cartItems } =
    React.useContext(CartContext);
  const { handelWishlist, handleWishListlDelete, wishListData } = React.useContext(WishListContext);
  const styles = useStyleConfig('Box', { variant: 'hoverBox' });
  const { courseId, setCourseId, getCourse } = useContext(CourseContext);
  const { userData } = useContext(AuthContext);
  const [values, setValues] = useState([]);
  const [redHeart, setRedHeart] = useState(false)

  const data = {
    courseName,
    img,
    courseLevel,
    originalPrice,
    offerPrice,
    rating,
    no_users,
    index,
    id,
    logo,
  };

  const { backendUrl } = useContext(EnvContext);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token');
  // const [tooltipLevel,setTooltipLevel]=useState(false)
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const isArray = arr => Array.isArray(arr);

  const isItemInCart = data => {
    const isInUnAuthCart =
      isArray(unAuthCartItems) && unAuthCartItems.some(item => item.id === id);
    const isInAuthCart =
      isArray(cartItems) && cartItems.some(item => item._id === id);
    const inEnrolled =
      isArray(userData?.courses) && userData?.courses.some(item => item?.course?._id === id);

    return (isInUnAuthCart || isInAuthCart);
  };
  const isItemEnrolled = data => {
    const inEnrolled =
      isArray(userData?.courses) && userData?.courses.some(item => item?.course?._id === id);
    return inEnrolled;
  };
  const itemInCart = isItemInCart(data);
  const itemEnrolled = isItemEnrolled(data);

  const tooltipLabel = itemEnrolled ? "Already enrolled" : itemInCart ? "Item already in the cart" : "";
  const cursorSymbol = (itemInCart || itemEnrolled) ? 'not-allowed' : 'pointer';



  // useEffect(() => {
  //   if (token) {
  //     console.log(token);
  //   }
  // }, [token]);

  const cardClick = id => { };
  const ratings = rating / no_users;

  const checkUserCred = data => {
    console.log("checkUserCred", data);

    if (location.pathname === '/products') handelAddCart(data);
    // else adminAddCart(data);
  };
  // console.log(backendUrl + img);

  const isItemWishlist = data => {
    const isWishList =
      isArray(wishListData) && wishListData.some(item => item?._id === id);
    return isWishList;
  };

  const isWishList = isItemWishlist();

  return (
    <Flex
      flexDirection={'column'}
      border="1px solid gray"
      justifyContent={'space-between'}
      w={{
        base: 300,
        md: 350,
        lg: '30%',
      }}
      h={{
        base: '60vh',
        md: '40vh',
        lg: '60vh',
      }}
      m="4"
      borderRadius="10"
      bg="white"
      transition={"transform 0.3s ease-in-out"}
      _hover={{
        ...styles._hover,
        transform: 'scale(1.02)',

      }}
      key={index}
    >
      <Box
        height={{
          base: '27vh',
          md: '20vh',
          lg: '30vh',
        }}
        borderTopRadius={10}
        borderBottom={"1px solid"}
        borderColor={"gray"}

      >
        <Image
          height="100%"
          width="100%"
          src={backendUrl + img}
          alt="jsCourse"
          borderTopRadius={10}
          objectFit={'cover'}
        />
      </Box>
      <Box m="5">
        <Box>
          <Heading fontSize="20px" fontWeight={700}>
            {courseName}
          </Heading>
          <Text>
            Course Level : <strong>{courseLevel}</strong>
          </Text>
          <Flex direction="row" alignItems="baseline" gap={3}>
            <Text>
              <strong>{ratings > 0 ? ratings : 0}</strong>
            </Text>
            <StarRating rating={rating} />
            <Text color="gray">({no_users})</Text>
          </Flex>
          <Flex direction="row" gap={4}>
            <Flex alignItems={'center'}>
              <MdOutlineCurrencyRupee />
              <Text className="strikethrough ">{originalPrice}</Text>
            </Flex>
            <Flex alignItems={'center'}>
              <FaIndianRupeeSign />
              <Text className="coursecard_pay">
                <strong>{offerPrice}</strong>
              </Text>
            </Flex>
          </Flex>
        </Box>
        <Flex alignItems={'center'} justifyContent={'flex-end'} gap={8}>
          {itemInCart || itemEnrolled ? (
            <Tooltip
              label={tooltipLabel} aria-label="A tooltip">
              <CustomCard>
                <MdAddShoppingCart
                  color="black"
                  size={'30px'}
                  cursor={cursorSymbol}
                  onClick={() => {
                    console.log("demo  add to cart")
                  }}
                />
              </CustomCard>
            </Tooltip>
          ) : (
            <MdAddShoppingCart
              color="black"
              size={'30px'}
              onClick={() => {
                checkUserCred(data);
              }}
              cursor='pointer'
            />
          )}
          {
            isWishList ? <FaHeart
              size={'22px'}
              cursor='pointer'
              color={'red'}
              onClick={() => {
                handleWishListlDelete(id);
                setRedHeart(!redHeart)

              }}
            />
              :
              <FaRegHeart
                size={'22px'}
                cursor='pointer'
                onClick={() => {
                  handelWishlist(id);
                  setRedHeart(!redHeart)

                }}
              />
          }


          {location.pathname === '/products' && (
            <Button
              colorScheme="blue"
              size="md"
              float="right"
              cursor='pointer'
              onClick={() => {
                setCourseId(id);
                navigate('/indi_course');
                console.log('course Id', id);
              }}
            >
              Explore Here
            </Button>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
