import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/AuthContext';
import {
  Flex,
  Avatar,
  Text,
  Divider,
  ListItem,
  ListIcon,
  List,
  HStack,
  Box,
  VStack,
  Heading
} from '@chakra-ui/react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { FaHandPointRight } from 'react-icons/fa6';
import { FaDiamond } from 'react-icons/fa6';
import UserDashboardSidebar from '../../../components/layouts/UserDashboardSidebar';
import useSaveLastRoute from '../../../hooks/useSaveLastRoute';

function BioDetails() {
  useSaveLastRoute();
  const { userData, handleUserData } = useContext(AuthContext);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [bio, setBio] = useState(false);
  useEffect(() => {
    console.log(userData.bio);
    console.log('Already have bio');
    setBio(true);
  }, [userData]);

  return (
    <>
      <Flex
        className="userHome"
        flexDirection={'row'}
        // w={'92%'}
        alignItems={'flex-start'}
        margin={'auto'}
        marginTop={10}
        maxW={'1300px'}
        justifyContent={'space-between'}
      >
        <UserDashboardSidebar />
        <VStack width={'81%'}>
          <Flex
            width={'100%'}
            position={'sticky'}
            bg={'white'}
            margin={{
              base: 0,
              lg: '0.75rem',
            }}
            padding={3}
            marginBottom={0}
            paddingBottom={7}
            paddingTop={4}
            top={0}
            zIndex={1}
          >
            <Heading
              pl={5}
              fontSize={'4xl'}
              lineHeight={{
                base: '2.2rem',
                lg: '1.2rem',
              }}
              bg={'white'}
            >
              Your Profile
            </Heading>
          </Flex>
          <Flex
            alignItems={'Center'}
            justifyContent={'flex-start'}
            mb={'25px'}
            gap={6}
            ml={6}
          >
            <Avatar
              size="2xl"
              name={userData?.name}
              src={`${BACKEND_URL}${userData?.dp}`}
            />
            <Flex
              direction={'column'}
              justifyContent={'space-between'}
              fontSize={20}
              h={'18vh'}
            >
              <Text>
                Email : <strong> {userData.email}</strong>
              </Text>
              <hr />
              <Text>
                Name : <strong>{userData.name}</strong>{' '}
              </Text>
              <hr />
              <Text>
                Phone : <strong>{userData.phone}</strong>{' '}
              </Text>
              <hr />
            </Flex>
          </Flex>
          <Divider orientation="horizontal" />
          {userData.bio ? (
            <Flex justifyContent={'space-between'} mt={5}>
              <Flex
                direction={'column'}
                fontSize={20}
                justifyContent={'flex-start'}
                gap={19}
              >
                <List
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  gap={8}
                >
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    CollegeName : <strong> {userData.bio?.collegeName}</strong>
                  </ListItem>

                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    CurrentSemester :{' '}
                    <strong> {userData.bio?.currentSemester}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    YOP : <strong> {userData.bio?.yop}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    Degree : <strong> {userData.bio?.degree}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    Branch : <strong> {userData.bio?.branch}</strong>
                  </ListItem>
                </List>
              </Flex>
              <Flex
                direction={'column'}
                fontSize={20}
                justifyContent={'space-between'}
                gap={19}
              >
                <List
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  gap={8}
                >
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    DOB : <strong> {userData.bio?.dob}</strong>
                  </ListItem>

                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    FatherName : <strong> {userData.bio?.fatherName}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    MotherName : <strong> {userData.bio?.motherName}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    Aadhar : <strong> {userData.bio?.aadhar}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    Pan : <strong> {userData.bio?.pan}</strong>
                  </ListItem>
                  <ListItem display={'flex'} alignItems={'center'} gap={2}>
                    <FaDiamond color="cyan" />
                    bloodGroup : <strong> {userData.bio?.bloodGroup}</strong>
                  </ListItem>
                </List>
              </Flex>
            </Flex>
          ) : (
            <Box>
              <Text textAlign={'center'} fontSize={'20px'}>
                You have no bio details{' '}
              </Text>
            </Box>
          )}
        </VStack>
      </Flex>
    </>
  );
}

export default BioDetails;
