import {
  Card,
  Stack,
  Image,
  CardBody,
  Heading,
  Text,
  Box,
  Flex,
  List,
  ListItem,
  ListIcon,
  CardHeader,
  CardFooter,
  Button,
  ButtonGroup,
  useColorModeValue as mode,
  Icon,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Center,
  VStack,
  HStack,
  useMediaQuery,
  Skeleton,
  Tooltip,
} from '@chakra-ui/react';

import React, { useEffect, useContext, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { MdOutlineCurrencyRupee } from 'react-icons/md';
import { IoBarChartSharp } from 'react-icons/io5';
import { PiClockCountdownFill } from 'react-icons/pi';
import { PiCertificateFill } from 'react-icons/pi';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import { IoArrowRedoSharp } from 'react-icons/io5';
import { FaUserAlt } from 'react-icons/fa';
import {
  certification,
  clock,
  dayasirphoto,
  demoUser,
  htmlbanner,
  mentorBackground,
  mentorDemo,
  steps,
  tutorbg,
  user,
} from '../../../assets/images';
import { StarRating } from '../../../components/elements/StarRating';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { video } from '../../../assets/video';
import { useCourse } from '../../../context/CourseListContext';
import EnvContext from '../../../context/EnvContext';
import { FaIndianRupeeSign } from 'react-icons/fa6';
import CartContext from '../../../context/CartContext';
import AuthContext from '../../../context/AuthContext';
import { CustomCard } from '../authentication/video/VideoList';
import { useNavigate } from 'react-router-dom';
import useSaveLastRoute from '../../../hooks/useSaveLastRoute';
import PaymentContext from '../../../context/PaymentContext';

export const IndiCourse = () => {
  useSaveLastRoute();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTab] = useMediaQuery('(max-width: 992px)');
  const unAuthItem = localStorage.getItem('unAuthCartItems');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { initiateThePayment } = useContext(PaymentContext);

  const { courseId, getNoAuthCourse, courseData } = useCourse();
  const { backendUrl } = useContext(EnvContext);
  const { handelAddCart, unAuthCartItems, cartItems, getcart } =
    useContext(CartContext);
  const { userData } = useContext(AuthContext);
  useEffect(() => {
    if (courseData) {
      console.log('courseData', courseData);
    }
  }, [courseData]);
  useEffect(() => {
    getNoAuthCourse();
  }, [courseId]);
  useEffect(() => {
    if (token && !unAuthItem) {
      console.log('token available, nothing in the auth cart local storage');
      getcart();
    }
  }, []);

  function calculateAverageRating(totalRatings, noOfReviews) {
    // Calculate the average rating
    const averageRating = totalRatings / noOfReviews;
    // Round the average rating to the nearest integer
    const roundedRating = Math.round(averageRating);
    console.log('roundedRating', roundedRating);
    if (isNaN(roundedRating)) {
      return 0;
    } else {
      // Return the rounded rating
      return roundedRating;
    }
  }

  const handleAddToCArt = () => {
    const data = {
      id: courseData._id,
      courseName: courseData.courseName,
      offerPrice: courseData.offerPrice,
      originalPrice: courseData.originalPrice,
    };
    console.log(data);
    handelAddCart(data);
  };

  const isArray = arr => Array.isArray(arr);

  const isItemInCart = data => {
    const isInUnAuthCart =
      isArray(unAuthCartItems) &&
      unAuthCartItems.some(item => item.id === courseData?._id);
    const isInAuthCart =
      isArray(cartItems) &&
      cartItems.some(item => item._id === courseData?._id);
    return isInUnAuthCart || isInAuthCart;
  };
  const isItemEnrolled = data => {
    const inEnrolled =
      isArray(userData?.courses) &&
      userData?.courses.some(item => item?.course?._id === courseData?._id);
    return inEnrolled;
  };
  const itemInCartF = isItemInCart();
  const itemEnrolledF = isItemEnrolled();

  const tooltipLabel = itemEnrolledF
    ? 'Already enrolled'
    : itemInCartF
    ? 'Item already in the cart'
    : '';
  const cursorSymbol = itemInCartF || itemEnrolledF ? 'not-allowed' : 'pointer';

  const handleUser = () => {
    const userId = localStorage.getItem('userId');
    const data = {
      courseIds: [courseId],
      userId: userId,
      isSubscription: false,
      courseData: courseData,
    };
    navigate('/indi_course/checkout', { state: { data } });
    if (localStorage.getItem('token')) {
      localStorage.setItem('route', '/indi_course');
    }
  };
  return (
    <Flex
      direction={'column'}
      bg={'#F9F4E8'}
      p={{
        base: '1rem 1rem',
        md: '1rem 1rem',
        lg: '1rem 3rem',
      }}
    >
      <Flex
        flexDir={{
          base: 'column',
          md: 'column',
          lg: 'row',
        }}
        rounded="lg"
        bg={'#faf9fd'}
        justifyContent={'space-around'}
        alignItems={{
          base: 'center',
          md: 'center',
        }}
        p={{
          base: '2',
          md: '2',
          lg: '2',
        }}
      >
        <VStack
          w={{
            base: '100%',
            md: '100%',
            lg: '60%',
          }}
          gap={'2rem'}
          px={4}
        >
          <Box
            w={{
              base: '95%',
            }}
            minHeight={{
              base: '27vh',
              sm: '27vh',
              md: '40vh',
              lg: '58vh',
            }}
            // backgroundColor={'blue'}
            position={'relative'}
          >
            {courseData?.banner ? (
              <Image
                id="indi_banner"
                src={backendUrl + courseData?.banner}
                height={'100%'}
                width={'100%'}
                objectFit={'cover'}
                position={'relative'}
                top={0}
                left={0}
              ></Image>
            ) : (
              <>
                <Skeleton height="58vh" width="100%" />
              </>
            )}
          </Box>
          <Box width={'90%'}>
            <marquee
              behavior="scroll"
              direction="left"
              scrollamount="5"
              style={{ color: 'red', fontWeight: 'bold', fontSize: '25px' }}
            >
              {courseData?.advQuote}
            </marquee>
          </Box>
        </VStack>
        <VStack
          w={{
            lg: '40%',
          }}
          alignItems={{
            base: 'flex-start',
            md: 'flex-start',
          }}
        >
          <Heading as="h3" size="lg" m={25}>
            {courseData?.courseName}
          </Heading>
          <Text
            textAlign={'justify'}
            overflowY={'auto'}
            scrollBehavior={'auto'}
            fontSize={{
              base: 'larger',
              md: 'x-large',
              lg: 'medium',
            }}
            maxH={{
              base: '30vh',
              md: '25vh',
              lg: '35vh',
            }}
            pr={5}
          >
            {courseData?.courseClassInterval}
          </Text>

          <Flex direction="row" gap={4}>
            <Flex alignItems={'center'}>
              <MdOutlineCurrencyRupee />
              <Text className="strikethrough ">
                {courseData?.originalPrice}
              </Text>
            </Flex>
            <Flex alignItems={'center'}>
              <FaIndianRupeeSign />
              <Text className="coursecard_pay">
                <strong>{courseData?.offerPrice}</strong>
              </Text>
            </Flex>
          </Flex>

          <Flex direction="row" alignItems="baseline" gap={3}>
            <Text>
              <strong>
                (
                {calculateAverageRating(
                  courseData?.totalRating,
                  courseData?.noOfReviews
                )}
                /5)
              </strong>
            </Text>
            <StarRating
              rating={calculateAverageRating(
                courseData?.totalRating,
                courseData?.noOfReviews
              )}
              h={50}
            />
            <Text color="gray">({courseData?.noOfReviews})</Text>
          </Flex>
          <HStack
            width={'100%'}
            justifyContent={'center'}
            spacing={{
              base: '4rem',
              md: '4rem',
              lg: '7rem',
            }}
          >
            {itemEnrolledF ? (
              <Box
                sx={{
                  borderLeft: '4px solid #3182CE', // Blue left border
                  paddingLeft: '1em', // Spacing between border and text
                  fontStyle: 'italic', // Italic font style
                  color: 'gray.600', // Gray text color
                  backgroundColor: 'gray.50', // Light gray background
                  padding: '1em', // Padding around the box
                  borderRadius: 'md', // Medium border radius for rounded corners
                  boxShadow: 'md', // Medium shadow for the box
                }}
              >
                <Text>You are already enrolled in this course</Text>
              </Box>
            ) : (
              <>
                <Button
                  float={'right'}
                  rightIcon={<FaLongArrowAltRight />}
                  colorScheme="blue"
                  variant="solid"
                  onClick={() => handleUser()}
                >
                  Buy now
                </Button>
                {itemInCartF || itemEnrolledF ? (
                  <Tooltip label={tooltipLabel} aria-label="A tooltip">
                    <CustomCard>
                      <Button
                        cursor="pointer"
                        colorScheme="red"
                        onClick={() => navigate('/cart')}
                      >
                        Go to cart
                      </Button>
                    </CustomCard>
                  </Tooltip>
                ) : (
                  <Button
                    onClick={() => {
                      handleAddToCArt();
                      console.log('courseData._id', courseData._id);
                    }}
                    colorScheme="blue"
                  >
                    Add to cart
                  </Button>
                )}
              </>
            )}
          </HStack>
        </VStack>
      </Flex>

      <Card my={15} bg={'#faf9fd'}>
        <CardBody
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-evenly'}
          p={{
            base: '3',
            md: '7',
            lg: '7',
          }}
        >
          <Flex align={'center'} gap={4}>
            <IoBarChartSharp size={20} />
            {/* <Image src={steps} alt="steps"></Image> */}
            <Box>
              <Text
                fontSize={{
                  base: '13px',
                  md: '20',
                  lg: '20',
                }}
                color={'#065e9b'}
              >
                Skill Level
              </Text>
              <Text
                fontSize={{
                  base: '14px',
                  md: '25',
                  lg: '25',
                }}
                fontWeight={'bold'}
                color={'#3293D0'}
              >
                {courseData?.courseLevel}
              </Text>
            </Box>
          </Flex>
          <Flex align={'center'} gap={4}>
            <PiClockCountdownFill size={30} />
            {/* <Image src={clock}></Image> */}
            <Box>
              <Text
                fontSize={{
                  base: '13px',
                  md: '20',
                  lg: '20',
                }}
                color={'#065e9b'}
              >
                Time to Complete
              </Text>
              <Text
                fontSize={{
                  base: '14px',
                  md: '25',
                  lg: '25',
                }}
                fontWeight={'bold'}
                color={'#3293D0'}
              >
                {' '}
                {courseData?.courseDurationHour} Hours
              </Text>
            </Box>
          </Flex>
          {courseData?.certification && (
            <Flex align={'center'} gap={4}>
              <PiCertificateFill size={30} />
              <Box>
                <Text
                  fontSize={{
                    base: '13px',
                    md: '20',
                    lg: '20',
                  }}
                  color={'#065e9b'}
                >
                  Certificate of completion
                </Text>
                <Text
                  fontSize={{
                    base: '14px',
                    md: '25',
                    lg: '25',
                  }}
                  fontWeight={'bold'}
                  color={'#3293D0'}
                >
                  Included
                </Text>
              </Box>
            </Flex>
          )}
        </CardBody>
      </Card>

      <HStack
        w={'100%'}
        p={2}
        pb={6}
        alignItems={'flex-start'}
        flexDirection={{
          base: 'column',
          md: 'column',
          lg: 'row',
        }}
      >
        <VStack
          w={{
            base: '100%',
            sm: '100%',
            md: '100%',
            lg: '50%',
          }}
          minWidth={'50%'}
        >
          <Box
            as="video"
            width="100%"
            controls
            key={courseData?.advVideo}
            onError={e => console.error('Video error:', e)}
          >
            <source
              src={`${backendUrl}${courseData?.advVideo}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </Box>

          {/* <LazyVideo src={`${backendUrl}${courseData?.advVideo}`} type="video/mp4" /> */}
        </VStack>
        <VStack alignItems={'flex-start'} p={5}>
          <VStack alignItems={'flex-start'}>
            <Heading as="h3" size={'lg'}>
              Course Facilities :
            </Heading>
            <VStack alignItems={'flex-start'}>
              <List spacing={3}>
                {courseData?.courseFacilities &&
                  Array.isArray(courseData.courseFacilities) &&
                  courseData.courseFacilities.map((el, i) => (
                    <ListItem
                      key={i}
                      fontSize={{
                        base: 'larger',
                        md: 'x-larger',
                      }}
                    >
                      <ListIcon as={MdOutlineDoubleArrow} color="#00008f" />
                      {el}
                    </ListItem>
                  ))}
              </List>
            </VStack>
          </VStack>
        </VStack>
      </HStack>

      <Box border={'1px solid'} bg={'#faf9fd'}>
        <Box fontSize="2xl" fontWeight="bold" mb={4} p={3}>
          Syllabus
        </Box>
        <Flex p={5} fontSize="xl" justify={'space-evenly'}>
          {courseData?.courseContent &&
            courseData?.courseContent?.length > 0 && (
              <Text flexGrow={1} bgColor={'orange'} textAlign={'center'}>
                `${courseData?.courseContent?.length} Contents`
              </Text>
            )}
          {courseData?.project && courseData?.project?.length > 0 && (
            <Text flexGrow={1} bgColor={'yellow'} textAlign={'center'}>
              `${courseData?.project?.length} projects`
            </Text>
          )}
          {courseData?.totalQnaCount > 0 && (
            <Text flexGrow={1} bgColor={'cyan'} textAlign={'center'}>
              `${courseData?.totalQnaCount} Assignments`
            </Text>
          )}
        </Flex>
        <Accordion allowToggle>
          {courseData?.syllabus?.chapters &&
            Array.isArray(courseData?.syllabus?.chapters) &&
            courseData?.syllabus?.chapters.map((el, i) => (
              <AccordionItem key={i}>
                <h2>
                  <AccordionButton>
                    <Text
                      color={'white'}
                      border={'1px solid'}
                      borderRadius={'50'}
                      bg={'#382d4d'}
                      w={42}
                      p={2}
                      fontWeight="bold"
                    >
                      {el.chapterNumber}
                    </Text>
                    <Box flex="1" textAlign="left" p={2} pl={4} fontSize="xl">
                      {el.chapterTitle}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                {el.topics &&
                  Array.isArray(el.topics) &&
                  el.topics.map((item, i) => (
                    <AccordionPanel py={0} fontSize="lg" key={i}>
                      <Flex
                        direction={'row'}
                        alignItems={'center'}
                        gap={3}
                        pl={10}
                        h={10}
                      >
                        <IoArrowRedoSharp color="#00008f" />
                        <Text>{item}</Text>
                      </Flex>
                    </AccordionPanel>
                  ))}
              </AccordionItem>
            ))}
        </Accordion>
      </Box>

      <Flex
        flexDirection={{
          base: 'column',
          md: 'row',
          lg: 'row',
        }}
        justifyContent={'space-between'}
        my={{
          base: 3,
          md: 5,
          lg: 6,
        }}
        alignItems={'center'}
        bg={'#faf9fd'}
        backgroundImage={tutorbg}
      >
        <Box
          w={{
            base: '35rem',
            lg: '40rem',
          }}
          textAlign={'center'}
          backgroundImage={mentorBackground}
          backgroundSize={{
            base: '66%',
            md: '100%',
            lg: '100%',
          }}
          backgroundPosition={{
            base: 'center',
            md: 'top',
          }}
          backgroundRepeat={'no-repeat'}
          display={'flex'}
          justifyContent={'center'}
        >
          {/* <Image w={'16rem'} h={'55vh'} src={backendUrl+courseData?.mentor?.dp} /> */}
          {courseData?.mentor?.dp ? (
            <Image
              w={{
                base: '13rem',
                md: '19rem',
                lg: '20rem',
              }}
              h={{
                base: '44vh',
                md: '37vh',
                lg: '55vh',
              }}
              // src={mentorDemo}

              src={backendUrl + courseData?.mentor?.dp}
            />
          ) : (
            <Image
              w={{
                base: '13rem',
                md: '19rem',
                lg: '20rem',
              }}
              h={{
                base: '44vh',
                md: '37vh',
                lg: '55vh',
              }}
              src={backendUrl + courseData?.mentor?.dp}
            />
          )}
        </Box>
        <Box
          w={{
            base: '21rem',
            md: '34rem',
            lg: '60rem',
          }}
          textAlign={'left'}
          p={5}
        >
          <Flex
            alignItems={'Flex-start'}
            gap={{ base: 2, lg: 8 }}
            direction={'column'}
          >
            <Heading>
              Meet our Tutor
              <br /> of the Course
            </Heading>
            {/* <Image src={dayasirphoto} height={100} width={100} alt="Tutor" /> */}
            <Box gap={5}>
              <Text fontWeight="bold" fontSize={25}>
                {' '}
                {courseData?.mentor?.name}
              </Text>
              <Text fontSize={18} color={'red'}>
                {courseData?.mentor?.designation}
              </Text>
            </Box>
          </Flex>
          <Text
            textAlign={'justify'}
            maxH={{
              base: '24vh',
              md: '17vh',
            }}
            overflowY={{ base: 'auto', md: 'auto' }}
          >
            {courseData?.mentor?.about}
          </Text>
          <Text fontSize={18} color={'red'}>
            Experience : {courseData?.mentor?.exp}
          </Text>
        </Box>
      </Flex>
      {isMobile ? (
        <Flex
          backgroundColor={'#4f5f6b'}
          direction={'column'}
          justify={'space-between'}
          py={30}
          h={'96vh'}
          alignItems={'center'}
        >
          <Flex direction="column" p={5}>
            <Heading color="white" size="lg">
              Unlock to our {courseData?.courseName} course
            </Heading>
            <List spacing={3} maxH={'38vh'} overflowY={'auto'}>
              {courseData?.courseRequirements &&
                Array.isArray(courseData?.courseRequirements) &&
                courseData?.courseRequirements.map((el, i) => (
                  <ListItem color={'white'}>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    {el}
                  </ListItem>
                ))}
            </List>
          </Flex>
          <Card
            w={'21rem'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <CardHeader>
              <Heading size="lg" float={'left'}>
                {' '}
                Package Summary
              </Heading>
            </CardHeader>
            <CardBody
              w={400}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Text
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                fontSize={20}
              >
                Original Price : <MdOutlineCurrencyRupee />{' '}
                {courseData?.originalPrice}
              </Text>
              <Text
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                fontSize={20}
              >
                Discounted Price : <MdOutlineCurrencyRupee />{' '}
                {courseData?.offerPrice}
              </Text>
            </CardBody>
            <CardFooter>
              <ButtonGroup spacing="2">
                <Button variant="solid" colorScheme="blue">
                  Buy now
                </Button>
                <Button variant="ghost" colorScheme="blue">
                  Add to cart
                </Button>
              </ButtonGroup>
            </CardFooter>
          </Card>
        </Flex>
      ) : (
        <Flex
          className="indi_course"
          direction={'row'}
          justify={'space-between'}
          py={30}
          h={{
            base: '60vh',
            md: '35vh',
            lg: '60vh',
          }}
        >
          <Flex
            direction="column"
            w={450}
            p={5}
            ml={{
              md: 0,
              lg: '5rem',
            }}
          >
            <Heading color="white" size="lg">
              Unlock to our {courseData?.courseName} course
            </Heading>
            <List
              spacing={3}
              maxH={{
                md: '40vh',
              }}
              mt={{
                md: 3,
              }}
              overflowY={'auto'}
            >
              {courseData?.courseRequirements &&
                Array.isArray(courseData?.courseRequirements) &&
                courseData?.courseRequirements.map((el, i) => (
                  <ListItem color={'white'} fontSize={{ md: 'larger' }}>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    {el}
                  </ListItem>
                ))}
            </List>
          </Flex>
          {isTab ? (
            <Card
              w={'21rem'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <CardHeader>
                <Heading size="lg" float={'left'}>
                  {' '}
                  Package Summary
                </Heading>
              </CardHeader>
              <CardBody
                w={400}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Text
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  fontSize={20}
                >
                  Original Price : <MdOutlineCurrencyRupee />{' '}
                  {courseData?.originalPrice}
                </Text>
                <Text
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  fontSize={20}
                >
                  Discounted Price : <MdOutlineCurrencyRupee />{' '}
                  {courseData?.offerPrice}
                </Text>
              </CardBody>
              <CardFooter>
                <ButtonGroup spacing="2">
                  <Button variant="solid" colorScheme="blue">
                    Buy now
                  </Button>
                  <Button variant="ghost" colorScheme="blue">
                    Add to cart
                  </Button>
                </ButtonGroup>
              </CardFooter>
            </Card>
          ) : (
            <Card
              w={{
                md: '30rem',
                lg: '40rem',
              }}
              mr={{
                md: '3rem',
                lg: '5rem',
              }}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <CardHeader>
                <Heading size="lg" float={'left'}>
                  {' '}
                  Package Summary
                </Heading>
              </CardHeader>
              <CardBody
                w={'100%'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-around'}
              >
                <HStack
                  border={'1px solid #efe7e7'}
                  w={'40%'}
                  h={'18vh'}
                  boxShadow={'1px 1px 3px -2px'}
                  borderRadius={'10px'}
                  justifyContent={'center'}
                >
                  <Text
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    fontSize={20}
                  >
                    Original Price : <MdOutlineCurrencyRupee />{' '}
                    {courseData?.originalPrice}
                  </Text>
                </HStack>
                <HStack
                  border={'1px solid #efe7e7'}
                  w={'40%'}
                  h={'18vh'}
                  boxShadow={'1px 1px 3px -2px'}
                  borderRadius={'10px'}
                  justifyContent={'center'}
                >
                  <Text
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    fontSize={20}
                  >
                    Discounted Price : <MdOutlineCurrencyRupee />{' '}
                    {courseData?.offerPrice}
                  </Text>
                </HStack>
              </CardBody>
              <CardFooter>
                <HStack
                  width={'100%'}
                  justifyContent={'center'}
                  spacing={{
                    base: '4rem',
                    md: '4rem',
                    lg: '7rem',
                  }}
                >
                  {itemEnrolledF ? (
                    <Box
                      sx={{
                        borderLeft: '4px solid #3182CE', // Blue left border
                        paddingLeft: '1em', // Spacing between border and text
                        fontStyle: 'italic', // Italic font style
                        color: 'gray.600', // Gray text color
                        backgroundColor: 'gray.50', // Light gray background
                        padding: '1em', // Padding around the box
                        borderRadius: 'md', // Medium border radius for rounded corners
                        boxShadow: 'md', // Medium shadow for the box
                      }}
                    >
                      <Text>You are already enrolled in this course</Text>
                    </Box>
                  ) : (
                    <>
                      <Button
                        float={'right'}
                        rightIcon={<FaLongArrowAltRight />}
                        colorScheme="blue"
                        variant="solid"
                        onClick={() => handleUser()}
                      >
                        Buy now
                      </Button>
                      {itemInCartF || itemEnrolledF ? (
                        <Tooltip label={tooltipLabel} aria-label="A tooltip">
                          <CustomCard>
                            <Button
                              cursor="pointer"
                              variant="ghost"
                              colorScheme="red"
                              onClick={() => navigate('/cart')}
                            >
                              Go to cart
                            </Button>
                          </CustomCard>
                        </Tooltip>
                      ) : (
                        <Button
                          onClick={() => {
                            handleAddToCArt();
                            console.log('courseData._id', courseData._id);
                          }}
                          variant="ghost"
                          colorScheme="blue"
                        >
                          Add to cart
                        </Button>
                      )}
                    </>
                  )}
                </HStack>
              </CardFooter>
            </Card>
          )}
        </Flex>
      )}
    </Flex>
  );
};
