import { createContext, useContext, useState } from 'react';
import EnvContext from './EnvContext';
import toast from 'react-hot-toast';
import axios from 'axios';

const SyllabusContext = createContext();

export const SyllabusContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [syllabusData, setSyllabusData] = useState({})
  const [token, setToken] = useState(null);

  let loadingToast;
  const [syllabusId, setSyllabusId] = useState(null);

  const postSyllabus = async (values, courseName) => {
    console.log(values);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const postData = {
        courseName: courseName,
        chapters: values.chapters,
      };
      loadingToast = toast.loading('Syllabus is adding  ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .post(`${backendUrl}/course/syllabus`, postData, { headers: headers })
        .then(res => {
          console.log('syllabuspost', res.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          setSyllabusId(res.data.syllabus._id);
        })
    } catch (err) {
      console.log("mentor error", err)
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const updateSyllabus = async (syllabusId, postData) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {

      loadingToast = toast.loading('Updating Syllabus...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .put(`${backendUrl}/course/syllabus?syllabusId=${syllabusId}`, postData, { headers: headers })
        .then(res => {
          console.log('syllabuspost', res.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getSyllabus(syllabusId)
        })
    } catch (err) {
      console.log("mentor error", err)
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const deleteSyllabus = async (syllabusId) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {

      loadingToast = toast.loading('Deleting Syllabus ...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .delete(`${backendUrl}/course/syllabus?syllabusId=${syllabusId}`, { headers: headers })
        .then(res => {
          console.log('syllabuspost', res.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getSyllabus(syllabusId)
        })
    } catch (err) {
      console.log("mentor error", err)
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const getSyllabus = async (value) => {
    const token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course/syllabus?syllabusId=${value}`, { headers: headers })
        .then(res => {
          console.log('Course Data', res.data);
          setSyllabusData(res.data.syllabus);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {

    }
  }


  return (
    <SyllabusContext.Provider value={{ deleteSyllabus, updateSyllabus, postSyllabus, syllabusId, setToken, getSyllabus, syllabusData, setSyllabusId, }}>
      {children}
    </SyllabusContext.Provider>
  );
};

export default SyllabusContext;
